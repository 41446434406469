import API from '../../services/Image';

const getLabeledImages = (projectID, taskID="") => {
    return {
        type: 'GET_LABELED_IMAGE',
        payload: API.getLabeledImages(projectID, taskID),
    };
};

const getAllImages = projectID => {
    return {
        type: 'GET_ALL_IMAGE',
        payload: API.getAllImages(projectID),
    };
};

const imageActions = {
    getLabeledImages,
    getAllImages,
};
export default imageActions;
