import axios from "axios";

axios.defaults.withCredentials = true;

const getProjects = (showAll) => {
  return axios.get(`/api/projects/?show_all=${showAll}`);
};

const createProject = (projectName, description) => {
  return axios.post("/api/new_project/", { name: projectName, description: description });
};

const createBatchProject = (projectId, data) => {
  return axios.post(`/api/project_batch_create/${projectId}/`, data);
};

const updateProject = (projectId, projectName, description, status) => {
  if (projectName === "" && description === "" && status === "") {
    return axios.delete(`/api/edit_project/${projectId}/`);
  }
  return axios.put(`/api/edit_project/${projectId}/`, { name: projectName, description: description, status: status });
};

const getProject = (id) => {
  return axios.get(`/api/project_info/${id}/`);
};

const getCount = (id) => {
  return axios.get(`/api/analysis/class_counts/${id}/?unclassified=True`);
};

const getProjectJson = (projectId) => {
  return axios.get(`/api/export_project_json/${projectId}/?page_pdf=True&export_document=True`);
};

const createBatchClass = (projectId, data) => {
  return axios.post(`/api/class_batch_create/${projectId}/`, data);
};

const duplicateProject = (projectId, copyImages, copyModels) => {
  return axios.post(`/api/make_project_copy/${projectId}/`, { copy_images: copyImages, copy_models: copyModels });
};

const projectServices = {
  getProjects,
  createProject,
  createBatchProject,
  updateProject,
  getProject,
  getCount,
  getProjectJson,
  createBatchClass,
  duplicateProject,
};

export default projectServices;
