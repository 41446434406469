// import _ from 'lodash'
import { v4 as uuidv4 } from "uuid";

const getProjectId = () => {
  const pathCells = window.location.pathname.split("/");
  if (pathCells[1] === "project") return pathCells[2];
  else return null;
};

const ocrEnable = {
  text: true,
  handwritten: true,
  signature: true,
  checkbox: true,
  address: true,
  datetime: true,
  numeric: true,
  qrcode: true,
  barcode: true,
  logo: false,
  stamp: false,
  image: false,
};

// https://mokole.com/palette.html
const colors = [
  "#ff8c31",
  "#38b48b",
  "#bc64a4",
  "#00bc12",
  "#db5a6b",
  "#8d4bbb",
  "#89c3eb",
  "#d7cf3a",
  "#2ca9e1",
  "#177cb0",
  "#e29c45",
  "#98d98e",
  "#e9546b",
  "#c89b40",
  "#ee827c",
  "#d69090",
  "#83ccd2",
  "#b0a4e3",
  "#edd1d8",
  "#1bd1a5",
  "#00e079",
  "#4a4266",
  "#ff7500",
  "#44cef6",
  "#ff4e20",
  "#c83c23",
  "#88ada6",
  "#3eede7",
  "#00bc12",
  "#fff143",
];

const group_colors = [
  "#483d8b",
  "#228b22",
  "#7f0000",
  "#808000",
  "#008b8b",
  "#000080",
  "#d2691e",
  "#7f007f",
  "#8fbc8f",
  "#b03060",
  "#ff0000",
  "#ffa500",
  "#0000cd",
  "#00ff00",
  "#dc143c",
  "#00ffff",
  "#00ff7f",
  "#f08080",
  "#adff2f",
  "#00bfff",
  "#da70d6",
  "#ff00ff",
  "#1e90ff",
  "#f0e68c",
  "#ffff54",
  "#90ee90",
  "#ff1493",
  "#7b68ee",
];

const template_key_color = "#FC6A03";
const template_value_color = "#7CB9E8";

const shortcuts = "1234567890qwertyuiopasdfghjklzxcvbnm";

const genId = () => {
  return uuidv4();
};

export function recursiveSearch(tree, value, key = "id", reverse = false) {
  const stack = [...tree];
  while (stack.length) {
    const node = stack[reverse ? "pop" : "shift"]();
    if (node[key] === value) return node;
    node.children && stack.push(...node.children);
  }
  return null;
}

// This function searches for an array within a parent array
// e.g. [[1, 2], [3, 4]], find [1, 2], -> get index
export function searchForArray(parentArray, targetArray) {
  var i, j, current;

  for (i = 0; i < parentArray.length; ++i) {
    if (targetArray.length === parentArray[i].length) {
      current = parentArray[i];
      for (j = 0; j < targetArray.length && targetArray[j] === current[j]; ++j);
      if (j === targetArray.length) return i;
    }
  }

  return -1;
}

export { getProjectId, ocrEnable, colors, group_colors, template_key_color, template_value_color, shortcuts, genId };
