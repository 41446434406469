import React, { Suspense, useEffect, useState } from "react";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-contexify/dist/ReactContexify.css";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import privateRoutes from "./routes/privateRoutes";
import AuthRoute from "./routes/AuthRoute";
import publicRoutes from "./routes/publicRoutes";
import _ from "lodash";
import ProjectAPI from "./services/Project";
import projectActions from "./store/action/ProjectActions";
import "./theme.less";
import taskServices from "./services/Task";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { logoutAction } from "./store/action";
import API from "./services/Account";
import Cookies from "js-cookie";
import "semantic-ui-css/semantic.min.css";

function App(props) {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const dispatch = useDispatch();

  const project = useSelector((state) => state.project, _.isEqual);
  const task = useSelector((state) => state.task, _.isEqual);

  const [activityTracker, setActivityTracker] = useState(null);

  useEffect(() => {
    async function fetchMyAPI() {
      const pathCells = pathname.split("/");
      if (pathCells[1] === "project" && !project.name) {
        const project = ProjectAPI.getProject(pathCells[2]);
        dispatch(projectActions.getProject(project));
      }
      if (pathCells[3] === "task" && !task.pk) {
        const task_response = await taskServices.getTasks();
        if (task_response.success) {
          task_response.list.forEach((item) => {
            if (item.pk.toString() === pathCells[4]) {
              dispatch(projectActions.setTask(item));
            }
          });
        }
      }
    }
    fetchMyAPI();
  });

  if (process.env.REACT_APP_SESSION_TIMEOUT > 0 && !activityTracker) {
    // for session auto logout
    document.addEventListener("mousemove", () => {
      localStorage.setItem("lastActivity", new Date());
    });
    document.addEventListener("click", () => {
      localStorage.setItem("lastActivity", new Date());
    });
    document.addEventListener("keydown", () => {
      localStorage.setItem("lastActivity", new Date());
    });

    let monitor = setInterval(async () => {
      let lastAcivity = localStorage.getItem("lastActivity");

      var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
      var seconds = Math.floor(diffMs / 1000);
      var minute = Math.floor(seconds / 60);

      // console.log(seconds + ' sec and ' + minute + ' min since last activity')
      // decimal comparison of minutes
      if (seconds / 60 >= process.env.REACT_APP_SESSION_TIMEOUT && Cookies.get("logged_in") === "true") {
        message.info(t("general_activity_logout", { limit: process.env.REACT_APP_SESSION_TIMEOUT }));

        await API.logout();
        logoutAction(dispatch);
        props.history.push("/");
      }
    }, 1000);

    setActivityTracker(monitor);
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {publicRoutes.map(({ path, component, ...routes }) => (
          <Route key={path} path={path} component={component} {...routes} />
        ))}
        {privateRoutes.map((route) => (
          <AuthRoute key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
}

export default withRouter(App);
