export const loginSuccess = (dispatch, data) => {
    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
            data: data,
            signed: true,
            status: 'LOGIN_SUCCESS',
        },
    });
};

export const loginReject = (dispatch, data) => {
    dispatch({
        type: 'LOGIN_REJECT',
        payload: {
            signed: false,
            status: 'LOGIN_REJECT',
            data: data,
        },
    });
};

export const logoutAction = dispatch => {
    dispatch({
        type: 'LOGOUT_ACTION',
        payload: {
            signed: false,
            status: 'LOGOUT_ACTION',
        },
    });
};
