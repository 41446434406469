import { FileDoneOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import { produce } from "immer";
import _ from "lodash";
import { colors, recursiveSearch, searchForArray, template_key_color, template_value_color } from "../../utils/util";

// If the size of incremental updates is greater than this number
// We might as well just full update
const INCREMENTAL_UPDATE_THRESHOLD = 20;

// variables needed for labeling activities
const initTemplateActivityState = {
  // current image meta
  currentImage: {},
  // current "page" this image is out of all other images for pagination of view.js
  // this should be 0 indexed because it represents the position in array of filtered Images
  page: 0,
  // Selected items in directory view on leftside
  selectedKeys: [],
  // which folders are open in leftside
  expandedKeys: [],
  // Image rendering size
  imageSize: 1024,
  // full directory tree data
  treeData: [],
  // shallow copy of all images held in treeData for fast lookup
  images: [],
  // copy of images, holding filtered images for pagination in View
  filteredImages: [],
  // total number of labeled files
  labeled: 0,
  // if we're on an add page, keep track of class/subclass id
  addPage: {
    classId: null,
    subclassId: null,
  },

  // a unique uuid to help make groups for redux-undo
  uuidForHistory: null,
  // Autosave status
  autoSave: false,
  // Whether or not the user has edited this image
  imageUpdated: false,
  // For autosave, if user edits while submitting, can lead to ghosted effects, so this needs to be here
  imageUpdatedWhileSaving: false,
  // Running inference initiates an async call that may update the page, so we want to prevent future bugs
  imageAboutToUpdate: false,

  // When an action that relates to a label is done, add it to this list
  // When auto save is on, we will save labels individually
  // however, there are some actions that affect a group of labels
  // we add these actions to fire off the normal save

  // array of type {
  //   type: string -> "update" | "delete",
  //   label: { figure_info... }
  // }
  incrementalUpdates: [],
  // simple true false to send a full update
  sendFullUpdate: false,

  // In order to not spam autosave when editing a label, we compare old/new text and only submit if changed
  oldFigureText: "",
  // rightsider toggle for inference
  autoSplit: false,
  // Selected polygon/bbox
  // Set to -1 to indicate no selection
  selectedFigureId: -1,
  // This list holds all the label ids that are selected, if multiple figures are selected, then selectedFigureId is reset to -1
  // When we do context menu actions, we will reference this list
  multipleFigureSelectionList: [],
  // selected value id, in the form of [parentKeyUuid, valueKeyUuid]
  selectedValueId: null,
  // multiple value selection
  // contains tuples of [parentKeyUuid, valueKeyUuid]
  multipleValueSelectionList: [],
  // Figure data
  figures: [],
  // Temporary clipboard for copying figures
  // valueFigures: is an array of objeccts of {parentUuid: parentFigureUuid, figure: valueFigure}
  figureClipboard: {
    imageSize: 1024,
    cursorLatLng: {},
    figures: [],
    valueFigures: [],
  },
  // We store the cursor position before move so we can calculate diff
  moveData: {
    isMoving: false,
    cursorLatLng: {},
  },
  // Drawing mode, bbox or polygon
  drawingMode: "bbox",
  // temporary data store for polygon/bbox user is making, will contain a list of points
  unfinishedFigure: null,
  // when adding a value label, keep track of the parent index
  valueSelectedParentIdx: -1,
  // separate figure for value figures
  unfinishedValueFigure: null,
  // Status of image edit submission
  currentlySubmitting: false,
  // If the last submission error'd, this is so we don't get into an infinite loop
  submissionErrored: false,
  // Whether or not we are waiting for a rotate
  // This is important because rotation is handled on the backend,
  // frontend just renders data, so we need to save first, then rotate
  pendingRotate: false,
};

// Wrap your case in {} to enforce scope if you declare any variables
export const templateActivityStateReducer = produce((draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case "template/CHANGE_IMAGE_SIZE":
      {
        const safeToSwitch = !draft.imageUpdated && !draft.imageAboutToUpdate;
        if (safeToSwitch) {
          draft.imageSize = payload;
        } else {
          if (draft.currentlySubmitting) {
            message.warn("Please wait for saving to complete");
          } else {
            message.warn("Please save the current labels first");
          }
        }
      }
      break;
    case "template/SET_CURRENT_IMAGE":
      // weird action called by dataperfection and eval detail
      // NEEDS TO BE REDONE
      draft.imageSize = 1024;
      draft.currentImage = payload;
      draft.selectedKeys = [payload.image_id];
      draft.expandedKeys = [payload.parentKey];
      break;
    case "template/UPDATE_LEFT_SIDER_PAGINATION":
      // correlates the clicked filename id with the page in pagination
      if (draft.currentImage && Object.keys(draft.currentImage).length > 0 && draft.filteredImages.length > 0) {
        let i = 0;
        for (; i < draft.filteredImages.length; i++) {
          if (draft.filteredImages[i].image_id === draft.currentImage.image_id) {
            draft.page = i;
            break;
          }
        }
        if (i === draft.filteredImages.length) {
          draft.page = 0;
          draft.currentImage = {};
        }
      }
      break;
    case "template/HANDLE_LEFT_SIDER_INIT":
      {
        const classes = payload.classes;
        let tree = [],
          imagesArray = [];
        // Since we're reloading data, unset current image
        draft.currentImage = {};
        draft.treeData = [];
        draft.images = [];

        Object.keys(classes).forEach((classId) => {
          let subTree = [];

          Object.keys(classes[classId].subclasses).forEach((subclassId) => {
            // Images that belong to no subgroup are in the "null" object

            const subclass = classes[classId].subclasses[subclassId];
            const is_default = subclassId === "null";
            const images = subclass.images.map((image) => {
              image.parentKey = `${classId}-${subclassId}`;
              if (!draft.currentImage) {
                draft.currentImage = image;
              }
              const temp = {
                title: (
                  <Tooltip placement="topLeft" title={image.name}>
                    <span
                      style={{
                        color: image.labelled ? "#52c41a" : "inherit",
                      }}
                    >
                      {image.name}
                    </span>
                  </Tooltip>
                ),
                key: `${classId}-${subclassId}-template`,
                value: `${classId}-${subclassId}-template`,
                classId: classId,
                subclassId: subclassId,
                image_id: image.image_id,
                name: image.name,
                isLeaf: true,
                icon: image.labelled && <FileDoneOutlined style={{ color: "#52c41a" }} />,
                labelled: image.labelled,
                parentKey: `${classId}-${subclassId}`,
              };

              if (classId === draft.addPage.classId && subclassId === draft.addPage.subclassId) {
                draft.currentImage = temp;
              }

              return temp;
            });
            imagesArray = imagesArray.concat(images);

            // if no template, add an "add" button disguised as an image
            if (images.length === 0) {
              images.push({
                title: "Add Image",
                key: `__add__${classId}-${subclassId}`,
                value: `__add__${classId}-${subclassId}`,
                name: "Add Image",
                isLeaf: true,
                icon: <PlusOutlined />,
                parentKey: `${classId}-${subclassId}`,
              });
            }

            subTree.push({
              title: is_default ? "Default" : subclass.name,
              key: `${classId}-${subclassId}`,
              value: `${classId}-${subclassId}`,
              isLeaf: false,
              children: images,
            });
          });

          tree.push({
            title: classes[classId].name,
            key: `${classId}`,
            value: `${classId}`,
            isLeaf: false,
            children: subTree,
          });
        });

        // This makes a shallow copy of each image metadata, allowing for quick lookups
        draft.images = imagesArray.filter((item) => item.isLeaf === true);
        draft.treeData = tree;
        // update filtered images here cuz we do no filtering
        draft.filteredImages = draft.images;

        if (!_.isEmpty(draft.currentImage)) {
          draft.selectedKeys = [draft.currentImage.key];
          draft.expandedKeys = [draft.currentImage.parentKey];
          // if we called this as a result of upload success
        }

        draft.addPage = {
          classId: null,
          subclassId: null,
        };

        draft.labeled = payload.finished;
      }
      break;
    case "template/DISREGARD_EDITS":
      // used right before user switches pages when auto save is off
      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      break;
    case "template/SWITCH_PAGE":
      {
        const safeToSwitch = !draft.imageUpdated && !draft.imageAboutToUpdate;
        if (safeToSwitch) {
          draft.page = _.clamp(payload.pageIdx, 0, draft.filteredImages.length - 1);
          draft.currentImage = draft.filteredImages[draft.page];
          draft.selectedKeys = [draft.currentImage.key];
          draft.expandedKeys = [draft.currentImage.parentKey];
          draft.addPage = {
            classId: null,
            subclassId: null,
          };
        } else {
          message.warn("Please wait for saving to complete");
        }

        // The "save?" check happens elsewhere, so we can safely discard all changes here
        draft.imageUpdated = false;
        draft.imageUpdatedWhileSaving = false;
      }
      break;
    case "template/GOTO_ADD_PAGE":
      {
        const safeToSwitch = !draft.imageUpdated && !draft.imageAboutToUpdate;
        if (safeToSwitch) {
          draft.currentImage = {};
          draft.addPage = {
            classId: payload.classId,
            subclassId: payload.subclassId,
          };
          draft.selectedKeys = [`__add__${payload.classId}-${payload.subclassId}`];
          draft.expandedKeys = [`${payload.classId}-${payload.subclassId}`];
        } else {
          message.warn("Please wait for saving to complete");
        }

        // The "save?" check happens elsewhere, so we can safely discard all changes here
        draft.imageUpdated = false;
        draft.imageUpdatedWhileSaving = false;
      }
      break;
    case "template/SET_EXPANDED_KEYS":
      draft.expandedKeys = payload;
      break;
    case "template/DESELECT_IMAGE":
      draft.page = 0;
      draft.currentImage = {};
      draft.addPage = {
        classId: null,
        subclassId: null,
      };
      break;
    case "template/RESET_ACTIVITY_ON_IMAGE_CHANGE":
      draft.selectedKeys = [];
      draft.addPage = {
        classId: null,
        subclassId: null,
      };
      break;
    case "template/RESET_LABEL_PAGE":
      return initTemplateActivityState;
    case "template/ESCAPE_KEY_HIT":
      // Don't unselect selection on cancel move
      if (draft.moveData.isMoving) {
        draft.moveData.isMoving = false;
      } else {
        draft.unfinishedFigure = null;
        draft.unfinishedValueFigure = null;
        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
        draft.selectedValueId = null;
        draft.multipleValueSelectionList = [];
      }
      break;
    case "template/RESET_ACTIVITY_ON_NO_IMAGE":
      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      draft.figures = [];
      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      draft.currentlySubmitting = false;
      draft.pendingRotate = false;
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];
      break;
    case "template/INFERENCE_UPDATE_FIGURE":
      {
        // we will update figures
        let figure = payload.figure;
        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["key_label_uuid", figure.key_label_uuid]);
          draft.figures.splice(index, 1);
        }

        let figureCopy = _.cloneDeep(figure);

        const {
          ocr_result: { confidence_score, pred },
        } = payload.resultList[0];

        figureCopy.user_result = pred;
        figureCopy.ocr_score = confidence_score;

        draft.figures.push(figureCopy);

        // auto scroll to the new figure
        draft.selectedFigureId = figure.key_label_uuid;
        // auto select it
        draft.multipleFigureSelectionList = [draft.selectedFigureId];

        // send the new figure to be saved
        // a single result will result in a one to one update
        // no need to delete old box if redrawing
        draft.incrementalUpdates.push({
          type: "update",
          label: figureCopy,
        });
      }
      break;
    case "template/INFERENCE_UPDATE_FIGURE_WITH_MULTIPLE_LINES":
      {
        const detectedFigureList = payload.detectedFigureList;
        const deleteFigureId = payload.deleteFigureId;

        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["id", deleteFigureId]);
          // when redrawing a one to many result, we have to delete the old figure
          // this also means we need to remove it from the group it was in

          if (deleteFigureId in draft.reverseFigureLookup) {
            let figure_group = draft.reverseFigureLookup[deleteFigureId];
            let groupIdx = figure_group[0];
            let subgroupIdx = figure_group[1];
            draft.groupData[groupIdx].labels[subgroupIdx].label_ids = draft.groupData[groupIdx].labels[
              subgroupIdx
            ].label_ids.filter((id) => id !== deleteFigureId);

            delete draft.reverseFigureLookup[deleteFigureId];
          }

          draft.incrementalUpdates.push({
            type: "delete",
            label: draft.figures[index],
          });
          draft.figures.splice(index, 1);
        }

        draft.figures = draft.figures.concat(detectedFigureList);

        draft.multipleFigureSelectionList = [];

        detectedFigureList.forEach((figure) => {
          // autoscroll to last new label
          draft.selectedFigureId = figure.key_label_uuid;
          draft.multipleFigureSelectionList = [figure.key_label_uuid];
          // send each new figure to be saved
          draft.incrementalUpdates.push({
            type: "update",
            label: figure,
          });
        });
      }
      break;
    case "template/INFERENCE_ADD_NON_OCR_FIGURE":
      {
        let figure = payload.figure;
        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        // instead of an inference result, we just append an empty figure/label
        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["key_label_uuid", figure.key_label_uuid]);
          draft.figures.splice(index, 1);
        }

        let figureCopy = _.cloneDeep(figure);
        figureCopy.user_result = "";

        draft.figures.push(figureCopy);

        // auto scroll to the new figure
        draft.selectedFigureId = figure.key_label_uuid;
        draft.multipleFigureSelectionList = [figure.key_label_uuid];

        // save new figure
        draft.incrementalUpdates.push({
          type: "update",
          label: figure,
        });
      }
      break;
    case "template/FINISH_INFERENCE":
      draft.imageUpdated = true;
      draft.imageAboutToUpdate = false;
      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }
      // draft.selectedFigureId = -1
      break;
    case "template/ADD_VALUE_LABEL":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      if (payload.redrawing) {
        draft.figures[draft.valueSelectedParentIdx].value_labels.splice(payload.newFigureIdx, 1);
      }

      draft.figures[draft.valueSelectedParentIdx].value_labels.push(payload.newFigure);

      // save new figure
      draft.incrementalUpdates.push({
        type: "update",
        label: draft.figures[draft.valueSelectedParentIdx],
      });
      break;
    case "template/SWITCH_DRAWING_MODE":
      draft.drawingMode = draft.drawingMode === "bbox" ? "polygon" : "bbox";

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/CLEAR_LABELS":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      draft.figures = [];

      // this is an action that should be batch auto saved
      draft.sendFullUpdate = true;
      break;
    case "template/START_MODEL_INFERENCE":
      draft.imageAboutToUpdate = true;
      draft.uuidForHistory = payload.uuid;
      break;
    // this is when you click apply model
    case "template/APPLY_MODEL_INFERENCE_RESULTS":
      {
        let newFigures = payload.newFigures;

        newFigures.forEach((figure) => {
          draft.figures.push(figure);
        });

        // applying model usually sends back a huge number of labels, so we might as well batch save
        draft.sendFullUpdate = true;
      }
      break;
    case "template/END_MODEL_INFERENCE":
      draft.imageUpdated = true;
      draft.imageAboutToUpdate = false;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      break;
    case "template/FIGURE_ENTRY_BEGIN":
      draft.oldFigureText = payload.text;
      draft.uuidForHistory = payload.uuid;
      break;
    case "template/FIGURE_ENTRY_TEXT_CHANGE":
      // We will update imageUpdated once user unfocuses textinput or hits enter
      // draft.imageUpdated = true

      draft.figures[payload.figureIndex].user_result = payload.text;
      break;
    case "template/FIGURE_ENTRY_COMMIT_CHANGE":
      if (draft.oldFigureText !== payload.text) {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      }
      draft.oldFigureText = "";

      // send individual update

      draft.incrementalUpdates.push({
        type: "update",
        label: draft.figures[payload.figureIndex],
      });
      break;
    case "template/DELETE_FIGURE_FROM_RIGHT_SIDER":
      {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        draft.incrementalUpdates.push({
          type: "delete",
          label: draft.figures[payload.figureIndex],
        });

        draft.figures.splice(payload.figureIndex, 1);

        draft.selectedFigureId = -1;
        draft.selectedValueId = null;
        draft.multipleFigureSelectionList = [];
        draft.multipleValueSelectionList = [];
      }
      break;
    case "template/DELETE_VALUE_FIGURE_FROM_RIGHT_SIDER":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      draft.incrementalUpdates.push({
        type: "update",
        label: draft.figures[payload.figureIndex],
      });

      draft.figures[payload.figureIndex].value_labels.splice(payload.valueFigureIdx, 1);

      draft.selectedFigureId = -1;
      draft.selectedValueId = null;
      draft.multipleFigureSelectionList = [];
      draft.multipleValueSelectionList = [];
      break;
    case "template/CHANGE_FIELD_EXPAND":
      // todo: draft.figures
      // draft.labelData[payload.fieldIdx].expand = !draft.labelData[payload.fieldIdx].expand;
      break;
    case "template/CLICKED_ON_ADD_FIELD":
      {
        draft.valueSelectedParentIdx = -1;

        draft.unfinishedValueFigure = null;
        draft.unfinishedFigure = {
          key_label_uuid: null,
          color: template_key_color,
          type: draft.drawingMode,
          points: [],
          value_labels: [],
        };

        draft.moveData.isMoving = false;

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
        draft.selectedValueId = null;
        draft.multipleValueSelectionList = [];
      }
      break;
    case "template/CLICKED_ON_ADD_VALUE_LABEL":
      draft.valueSelectedParentIdx = payload.figureIdx;

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = {
        value_label_uuid: null,
        color: template_value_color,
        type: draft.drawingMode,
        points: [],
      };

      draft.moveData.isMoving = false;

      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];
      break;
    case "template/CLICKED_ON_FIGURE":
      draft.selectedFigureId = payload.figureId;
      draft.multipleFigureSelectionList = [payload.figureId];

      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/CLICKED_ON_VALUE_FIGURE":
      draft.selectedValueId = [payload.parentFigureId, payload.valueFigureId];
      draft.multipleValueSelectionList = [[payload.parentFigureId, payload.valueFigureId]];

      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/CLICKED_ON_FIGURE_IN_PANEL":
      if (payload.multiple) {
        draft.selectedFigureId = payload.figureId;
        if (!draft.multipleFigureSelectionList.includes(payload.figureId)) {
          draft.multipleFigureSelectionList.push(payload.figureId);
        }
      } else {
        draft.selectedFigureId = payload.figureId;
        draft.multipleFigureSelectionList = [payload.figureId];

        draft.selectedValueId = null;
        draft.multipleValueSelectionList = [];
      }

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/CLICKED_ON_VALUE_FIGURE_IN_PANEL":
      if (payload.multiple) {
        draft.selectedValueId = [payload.parentFigureId, payload.valueFigureId];
        if (searchForArray(draft.multipleValueSelectionList, [payload.parentFigureId, payload.valueFigureId]) === -1) {
          draft.multipleValueSelectionList.push([payload.parentFigureId, payload.valueFigureId]);
        }
      } else {
        draft.selectedValueId = [payload.parentFigureId, payload.valueFigureId];
        draft.multipleValueSelectionList = [[payload.parentFigureId, payload.valueFigureId]];

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
      }

      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/BEGIN_MULTI_SELECT":
      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/MULTI_SELECT_FIGURES":
      draft.selectedFigureId = -1;
      draft.selectedValueId = null;
      // only add new figures, and don't double add
      payload.figureIds.forEach((figureId) => {
        if (!draft.multipleFigureSelectionList.includes(figureId)) {
          draft.multipleFigureSelectionList.push(figureId);
        }
      });

      payload.valueFigureIds.forEach((valueFigureTuple) => {
        if (searchForArray(draft.multipleValueSelectionList, valueFigureTuple) === -1) {
          draft.multipleValueSelectionList.push(valueFigureTuple);
        }
      });
      break;
    case "template/BEGIN_INFERENCE":
      draft.imageAboutToUpdate = true;
      break;
    case "template/FINISH_BBOX_DRAWING":
    case "template/FINISH_POLYGON_DRAWING":
      // don't set updated here because we'll do inference right after
      // draft.imageUpdated = true
      // Inference auto starts after this, which should update figures for us
      // draft.figures.push(payload)
      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      break;
    case "template/DELETED_POLYGON_POINT":
    case "template/MOVED_BBOX_POINT":
    case "template/MOVED_POLYGON_POINT":
    case "template/SUBDIVIDE_POLYGON_EDGE":
      {
        // don't set updated here because we'll do inference right after
        // draft.imageUpdated = true
        draft.figures[payload.keyFigureIdx] = payload.newFigure;
        draft.uuidForHistory = payload.uuid;
      }
      break;
    case "template/VALUE_DELETED_POLYGON_POINT":
    case "template/VALUE_MOVED_BBOX_POINT":
    case "template/VALUE_MOVED_POLYGON_POINT":
    case "template/VALUE_SUBDIVIDE_POLYGON_EDGE":
      {
        // don't set updated here because we'll do inference right after
        // draft.imageUpdated = true
        draft.figures[payload.parentFigureIdx].value_labels[payload.figureIdx] = payload.newFigure;
        draft.uuidForHistory = payload.uuid;
      }
      break;
    case "template/ADD_POINT_TO_UNFINISHED_FIGURE":
      draft.unfinishedFigure.points.push(payload.point);
      draft.uuidForHistory = payload.uuid;
      break;
    case "template/VALUE_ADD_POINT_TO_UNFINISHED_FIGURE":
      draft.unfinishedValueFigure.points.push(payload.point);
      draft.uuidForHistory = payload.uuid;
      break;
    case "template/CLICK_ON_VIEW":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];
      break;
    case "template/ADD_INIT_LABEL_DATA":
      {
        const labels = payload.labels;
        // const image_id = payload.image_id;

        draft.figures = [];
        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];

        let figures = labels
          ? labels.map((item) => {
              let new_point = item.points.map((point) => {
                return {
                  lng: point.x,
                  lat: point.y,
                };
              });
              return {
                ...item,
                points: new_point,
                color: template_key_color,
                value_labels: item.value_labels.map((value_label) => {
                  return {
                    ...value_label,
                    color: template_value_color,
                    points: value_label.points?.map((point) => {
                      return {
                        lng: point.x,
                        lat: point.y,
                      };
                    }),
                  };
                }),
              };
            })
          : [];
        draft.figures = draft.figures.concat(figures);
      }
      break;
    case "template/REQUEST_ROTATE_IMAGE":
      draft.pendingRotate = true;
      break;
    case "template/ROTATION_FINISHED":
      draft.pendingRotate = false;
      // if user decides to not save before rotating
      draft.imageUpdated = false;
      break;
    // When user hits delete on keyboard when figure is selected
    // we will loop over every id selected
    case "template/REQUEST_FIGURE_DELETE":
      draft.multipleValueSelectionList.forEach((valueFigureTuple) => {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        const figureIdx = _.findIndex(draft.figures, ["key_label_uuid", valueFigureTuple[0]]);

        draft.incrementalUpdates.push({
          type: "update",
          label: draft.figures[figureIdx],
        });

        const valueFigureIdx = _.findIndex(draft.figures[figureIdx], ["value_label_uuid", valueFigureTuple[1]]);

        draft.figures[figureIdx].value_labels.splice(valueFigureIdx, 1);
      });

      draft.multipleFigureSelectionList.forEach((figureId) => {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        const figureIdx = _.findIndex(draft.figures, ["key_label_uuid", figureId]);

        draft.incrementalUpdates.push({
          type: "delete",
          label: draft.figures[figureIdx],
        });

        // remove figure from screen
        draft.figures.splice(figureIdx, 1);
      });

      draft.multipleValueSelectionList = [];
      draft.multipleFigureSelectionList = [];
      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }

      break;
    case "template/SUBMISSION_BEGIN":
      draft.currentlySubmitting = true;
      draft.imageUpdatedWhileSaving = false;
      draft.submissionErrored = false;
      // if we are full saving, we don't need to keep any incremental updates
      draft.sendFullUpdate = false;
      draft.incrementalUpdates = [];
      break;
    case "template/SUBMISSION_SUCCESS_UPDATE":
      {
        // if the label page has been reset, just quit
        if (draft.currentImage.image_id) {
          if (!_.isEqual(draft.treeData, [])) {
            // if we're in eval detail edit mode, we don't have a left sider
            let trueImage = recursiveSearch(draft.treeData, draft.currentImage.image_id, "image_id");

            trueImage.icon =
              draft.figures.length > 0 ? <FileDoneOutlined style={{ color: "#52c41a" }} /> : <FileOutlined />;
            trueImage.title = (
              <Tooltip placement="topLeft" title={trueImage.name}>
                <span style={draft.figures.length > 0 ? { color: "#52c41a" } : {}}>{trueImage.name}</span>
              </Tooltip>
            );

            let add_flag = !trueImage.labelled && draft.figures.length > 0;
            let decrease_flag = draft.figures.length === 0 && trueImage.labelled;

            trueImage.labelled = draft.figures.length > 0;

            if (add_flag) {
              draft.labeled += 1;
            } else if (decrease_flag) {
              draft.labeled -= 1;
            }
          }

          draft.imageUpdated = false;
        }
      }
      break;
    case "template/SUBMISSION_FINISH":
      draft.currentlySubmitting = false;
      break;
    case "template/SUBMISSION_ERROR":
      draft.submissionErrored = true;
      break;
    case "template/INCREMENTAL_SUBMISSION_BEGIN":
      draft.currentlySubmitting = true;
      draft.imageUpdatedWhileSaving = false;
      draft.submissionErrored = false;
      break;
    case "template/INCREMENTAL_SUBMISSION_SUCCESS_UPDATE":
      // if the label page has been reset, just quit
      if (draft.currentImage.image_id) {
        // now we need to remove the update from the list of pending updates to send to server
        let index = draft.incrementalUpdates.findIndex((update) => update.label.key_label_uuid === payload.label_uuid);
        draft.incrementalUpdates.splice(index, 1);
      }
      break;
    case "template/INCREMENTAL_SUBMISSION_FINISH":
      // only update left sider once
      if (draft.currentImage.image_id) {
        if (!_.isEqual(draft.treeData, [])) {
          // if we're in eval detail edit mode, we don't have a left sider
          let trueImage = recursiveSearch(draft.treeData, draft.currentImage.image_id, "image_id");

          trueImage.icon =
            draft.figures.length > 0 ? <FileDoneOutlined style={{ color: "#52c41a" }} /> : <FileOutlined />;
          trueImage.title = (
            <Tooltip placement="topLeft" title={trueImage.name}>
              <span style={draft.figures.length > 0 ? { color: "#52c41a" } : {}}>{trueImage.name}</span>
            </Tooltip>
          );

          let add_flag = !trueImage.labelled && draft.figures.length > 0;
          let decrease_flag = draft.figures.length === 0 && trueImage.labelled;

          trueImage.labelled = draft.figures.length > 0;

          if (add_flag) {
            draft.labeled += 1;
          } else if (decrease_flag) {
            draft.labeled -= 1;
          }
        }
      }
      draft.imageUpdated = false;
      draft.currentlySubmitting = false;
      break;
    case "template/SWITCH_AUTO_SPLIT":
      draft.autoSplit = !draft.autoSplit;
      break;
    case "template/SWITCH_AUTO_SAVE":
      draft.autoSave = !draft.autoSave;
      break;
    case "template/LABEL_UNDO_REDO_IMAGE_UPDATED":
      draft.imageUpdated = true;
      draft.sendFullUpdate = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      break;
    case "template/COPY_FIGURES":
      // we have to check length of selection because user can call this via keybind
      if (draft.multipleFigureSelectionList.length > 0 || draft.multipleValueSelectionList.length > 0) {
        draft.figureClipboard.size = draft.imageSize;
        draft.figureClipboard.cursorLatLng = payload.cursorLatLng;
        draft.figureClipboard.figures = draft.figures.filter((figure) =>
          draft.multipleFigureSelectionList.includes(figure.key_label_uuid)
        );

        draft.multipleValueSelectionList.forEach((valueFigureTuple) => {
          const parentFigureIdx = draft.figures.findIndex((figure) => figure.key_label_uuid === valueFigureTuple[0]);
          if (parentFigureIdx !== -1) {
            const valueFigureIdx = draft.figures[parentFigureIdx].value_labels.findIndex(
              (valueFigure) => valueFigure.value_label_uuid === valueFigureTuple[1]
            );

            draft.figureClipboard.valueFigures.push({
              parentUuid: valueFigureTuple[0],
              figure: draft.figures[parentFigureIdx].value_labels[valueFigureIdx],
            });
          }
        });
      }
      break;
    case "template/PASTE_FIGURES":
      {
        if (payload.newFigures.length > 0 || payload.newValueFigures.length > 0) {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

          draft.multipleFigureSelectionList = [];

          payload.newFigures.forEach((figure) => {
            // for each new figure add it to figures array
            draft.figures.push(figure);

            // select pasted figures
            draft.multipleFigureSelectionList.push(figure.key_label_uuid);

            // update incremental updates
            draft.incrementalUpdates.push({
              type: "update",
              label: figure,
            });
          });

          payload.newValueFigures.forEach((valueFigureObj) => {
            const parentFigureIdx = draft.figures.findIndex(
              (figure) => figure.key_label_uuid === valueFigureObj.parentUuid
            );
            // add copied value figures to the same parent
            if (parentFigureIdx !== -1) {
              draft.figures[parentFigureIdx].value_labels.push(valueFigureObj.figure);
            }

            draft.multipleValueSelectionList.push([valueFigureObj.parentUuid, valueFigureObj.figure.value_label_uuid]);

            draft.incrementalUpdates.push({
              type: "update",
              label: draft.figures[parentFigureIdx],
            });
          });

          if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
            draft.sendFullUpdate = true;
          }
        }
      }
      break;
    case "template/BATCH_OCR_START":
      draft.uuidForHistory = payload.uuid;
      break;
    case "template/MOVE_START":
      draft.moveData.isMoving = true;
      draft.moveData.cursorLatLng = payload.cursorLatLng;
      break;
    case "template/MOVE_END":
      draft.moveData.isMoving = false;
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      const latDiff = payload.cursorLatLng.lat - draft.moveData.cursorLatLng.lat;
      const lngDiff = payload.cursorLatLng.lng - draft.moveData.cursorLatLng.lng;

      draft.multipleFigureSelectionList.forEach((figureId) => {
        // update figures and labelData
        // also delete ocr results

        const matchingFigureIdx = draft.figures.findIndex((figure) => figure.key_label_uuid === figureId);

        const newPoints = [];
        draft.figures[matchingFigureIdx].points.forEach((point) => {
          newPoints.push({
            lat: point.lat + latDiff,
            lng: point.lng + lngDiff,
          });
        });

        draft.figures[matchingFigureIdx].points = newPoints;
        draft.figures[matchingFigureIdx].user_result = "";
        draft.figures[matchingFigureIdx].ocr_result = null;
        delete draft.figures[matchingFigureIdx].ocr_score;
        delete draft.figures[matchingFigureIdx].det_score;

        // incremental updates
        draft.incrementalUpdates.push({
          type: "update",
          label: draft.figures[matchingFigureIdx],
        });
      });

      draft.multipleValueSelectionList.forEach((valueFigureTuple) => {
        const parentFigureIdx = draft.figures.findIndex((figure) => figure.key_label_uuid === valueFigureTuple[0]);
        const valueFigureIdx = draft.figures[parentFigureIdx].value_labels.findIndex(
          (value_label) => value_label.value_label_uuid === valueFigureTuple[1]
        );

        const newPoints = [];
        draft.figures[parentFigureIdx].value_labels[valueFigureIdx].points.forEach((point) => {
          newPoints.push({
            lat: point.lat + latDiff,
            lng: point.lng + lngDiff,
          });
        });

        draft.figures[parentFigureIdx].value_labels[valueFigureIdx].points = newPoints;

        draft.incrementalUpdates.push({
          type: "update",
          label: draft.figures[parentFigureIdx],
        });
      });

      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }
      break;
    case "template/SELECT_ALL_FIGURES":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = draft.figures.map((figure) => figure.key_label_uuid);

      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];
      draft.figures.forEach((figure) => {
        figure.value_labels.forEach((value_label) => {
          draft.multipleValueSelectionList.push([figure.key_label_uuid, value_label.value_label_uuid]);
        });
      });
      break;
    case "template/DELETE_TEMPLATE":
      // reset label variables

      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      draft.figures = [];
      draft.unfinishedFigure = null;
      draft.unfinishedValueFigure = null;
      draft.currentlySubmitting = false;
      draft.pendingRotate = false;

      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      draft.selectedValueId = null;
      draft.multipleValueSelectionList = [];

      // remove image from images list

      const imageIdx = draft.images.findIndex((image) => {
        return image.image_id === draft.currentImage.image_id;
      });

      draft.images.splice(imageIdx, 1);

      // remove image from treeData and replace it with the add Image button

      const treeClassIdx = draft.treeData.findIndex((classObj) => {
        return classObj.key === `${payload.classId}`;
      });

      const treeSubclassIdx = draft.treeData[treeClassIdx].children.findIndex((subclassObj) => {
        return subclassObj.key === `${payload.classId}-${payload.subclassId}`;
      });

      const imageTdIdx = draft.treeData[treeClassIdx].children[treeSubclassIdx].children.findIndex((image) => {
        return image.image_id === draft.currentImage.image_id;
      });

      // Remove current Image, and select the add image button

      draft.treeData[treeClassIdx].children[treeSubclassIdx].children.splice(imageTdIdx, 1);

      if (draft.treeData[treeClassIdx].children[treeSubclassIdx].children.length === 0) {
        draft.treeData[treeClassIdx].children[treeSubclassIdx].children.push({
          title: "Add Image",
          key: `__add__${payload.classId}-${payload.subclassId}`,
          value: `__add__${payload.classId}-${payload.subclassId}`,
          name: "Add Image",
          isLeaf: true,
          icon: <PlusOutlined />,
          parentKey: `${payload.classId}-${payload.subclassId}`,
        });
      }

      draft.currentImage = {};
      draft.addPage = {
        classId: payload.classId,
        subclassId: payload.subclassId,
      };
      draft.selectedKeys = [`__add__${payload.classId}-${payload.subclassId}`];
      draft.expandedKeys = [`${payload.classId}-${payload.subclassId}`];
      break;
    default:
      break;
  }
}, initTemplateActivityState);
