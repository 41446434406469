import API from '../../services/Class';

const getClassData = projectID => {
    return {
        type: 'GET_CLASS_DATA',
        payload: API.getClassData(projectID),
    };
};

const getSubclassData = classId => {
    return {
        type: 'GET_SUBCLASS_DATA',
        payload: API.getSubclassData(classId),
    }
}

const setCurrentClassId = classId => {
    return {
        type: 'SET_CURRENT_CLASS_ID',
        payload: classId,
    }
}

const setCurrentClassName = classId => {
    return {
        type: 'SET_CURRENT_CLASS_NAME',
        payload: classId,
    }
}

const classActions = {getClassData, getSubclassData, setCurrentClassId, setCurrentClassName};
export default classActions;
