import {produce} from "immer";

const initialFileState = {
    allFiles: []
};

export const FileStateReducer = produce((draft, action) => {
    const { type, payload } = action
    switch(type) {
        case 'file/ADD_FILES':
            draft.allFiles = [...draft.allFiles, ...payload.fileArray]
            break
        case 'file/REMOVE_FILE': {
            const fileIdxToBeRemoved = draft.allFiles.findIndex(file => file.uuid === payload.file.uuid)
            draft.allFiles.splice(fileIdxToBeRemoved, 1)
        } break
        case 'file/REMOVE_DUPLICATE_FILES_EXCEPT_FIRST': {
            let fileUUidsToBeRemoved = []
            draft.allFiles.forEach(file => payload.duplicateFilenamesDict[file.name] ? fileUUidsToBeRemoved.push(file.uuid) : payload.duplicateFilenamesDict[file.name] = true)
            draft.allFiles = draft.allFiles.filter(file => !fileUUidsToBeRemoved.includes(file.uuid))
        } break
        case 'file/REMOVE_ALL_FILES':
            draft.allFiles = []
            break
        case 'file/UPLOAD_FINISHED':
            draft.allFiles = [...payload.failedFiles]
            break
        default:
            break
    }
}, initialFileState)
