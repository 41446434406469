import axios from 'axios'
import Cookies from 'js-cookie';

axios.defaults.withCredentials = true;

let currentRequestInterceptor = null;

export default function interceptors() {
    axios.interceptors.response.use((response) => {
        const {statusText, status} = response

        let data = response.data;
        if (data instanceof Array) {
            data = {
                list: data,
            }
        }

        return {
            success: true,
            message: statusText,
            statusCode: status,
            ...data,
        }
    }, (error) => {
        const {response} = error
        let msg;
        let statusCode;
        let errorMsg = ''
        if (response && response instanceof Object) {
            const {data, statusText} = response;
            statusCode = response.status;
            msg = data.message || statusText;
            if (statusCode === 401 || statusCode === 403) {
                Cookies.remove("logged_in");
                Cookies.remove('state');
                Cookies.remove('csrftoken');
                Cookies.remove('username');
                Cookies.remove('organization_name');
                Cookies.remove('sessionid');
                Cookies.remove('projectInfo');
                Cookies.remove('list');
                Cookies.remove('projectName');
                window.location.replace('/');
            }
            errorMsg = data
        } else {
            statusCode = 600;
            msg = error.message || 'Network Error';
            errorMsg = error.message || 'Network Error'
        }
        
        return {success: false, statusCode, message: msg, error: errorMsg}
    })

    if(currentRequestInterceptor) axios.interceptors.request.eject(currentRequestInterceptor);

    currentRequestInterceptor = axios.interceptors.request.use((config) => {
        config.headers['X-CSRFToken'] = Cookies.get('csrftoken')

        return config
    }, function (error) {
        return Promise.reject(error)
    })
}




