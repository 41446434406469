import axios from "axios";

axios.defaults.withCredentials = true;

const getLoginCSRF = async () => {
    return axios.get('/api/get_login_csrf/');    
}

const login = async (username, password) => {
    return axios.post('/api/login/', {username, password});
}

const logout = async () => {
    return axios.post(
        '/api/logout/', null)
};

const signup = async data => {
    return await axios.post('/api/register/', data);
};

const sendEmail = async email => {
    return axios.post('/api/reset_password/', {email});
}

const resetPassword = async (token, new_password) => {
    return axios.put('/api/change_password/', {token, new_password});
}

const SMALlogin = async () => {
    return axios.post('/saml2/login/')
}

const accountServices = {getLoginCSRF, login, logout, signup, sendEmail, resetPassword, SMALlogin};
export default accountServices;
