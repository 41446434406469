import { combineReducers } from "redux";
import { AuthReducer } from "./Auth";
import { ProjectsReducer, ProjectReducer, CollapsedReducer, TaskReducer } from "./Project";
import { ImageReducer } from "./Image";
import { ClassReducer, selectedClassIdReducer, selectedClassNameReducer } from "./Class";
import { ResultsReducer } from "./Inference";
import { SchemaReducer } from "./Schema";
import { labelActivityStateReducer } from "./Label";
import { FileStateReducer } from "./File";
import {
  CurModelTabKeyReducer,
  RegressionDetailsReducer,
  EvaluationPreviewDataReducer,
  EvaluationPreviewModalReducer,
  EvaluationPreviewImageIDReducer,
  AssignedModelReducer,
} from "./Model";
import undoable, { excludeAction, includeAction } from "redux-undo";
import { templateActivityStateReducer } from "./Template";

const rootReducer = combineReducers({
  auth: AuthReducer,
  projects: ProjectsReducer,
  image: ImageReducer,
  class: ClassReducer,
  selectedClassId: selectedClassIdReducer,
  selectedClassName: selectedClassNameReducer,
  project: ProjectReducer,
  schema: SchemaReducer,
  results: ResultsReducer,
  collapsed: CollapsedReducer,

  labelActivityState: undoable(labelActivityStateReducer, {
    undoType: "label/LABEL_UNDO",
    redoType: "label/LABEL_REDO",
    filter: excludeAction([
      "label/SUBMISSION_BEGIN",
      "label/SUBMISSION_SUCCESS_UPDATE",
      "label/SUBMISSION_FINISH",
      "label/INCREMENTAL_SUBMISSION_BEGIN",
      "label/INCREMENTAL_SUBMISSION_SUCCESS_UPDATE",
      "label/INCREMENTAL_SUBMISSION_FINISH",
      "label/SUBMISSION_ERROR",
      "label/RESET_LABEL_PAGE",
      "label/RESET_ACTIVITY_ON_NO_IMAGE",
      "label/HANDLE_LEFT_SIDER_INIT",
      "label/UPDATE_FILTERED_IMAGES",
      "label/UPDATE_LEFT_SIDER_PAGINATION",
      "label/CLICK_ON_VIEW",
      "label/GROUP_MODE_CLICK_ON_FIGURE_ENTRY",
      "label/CLICKED_ON_FIELD",
      "label/CLICKED_ON_FIGURE_IN_PANEL",
      "label/CLICKED_ON_FIGURE",
      "label/BEGIN_MULTI_SELECT",
      "label/MULTI_SELECT_FIGURES",
      "label/TOGGLE_LABEL_GROUP_MODE",
      "label/ADD_INIT_LABEL_DATA",
      "label/SWITCH_PAGE",
      "label/LABEL_UNDO_REDO_IMAGE_UPDATED",
      "label/CLICK_SHOW_ALL_LABELS_OF_GROUP",
      "label/RESET_SELECTED_GROUP_ID",
      "label/COPY_FIGURES",
      "label/MOVE_START",
      "label/SELECT_ALL_FIGURES",
      "label/SET_TEMPLATE_LABELDATA",
    ]),
    groupBy: (action, currentState, previousHistory) => {
      if (
        [
          "label/ADD_POINT_TO_UNFINISHED_FIGURE",
          "label/MOVED_BBOX_POINT",
          "label/MOVED_POLYGON_POINT",
          "label/FINISH_BBOX_DRAWING",
          "label/FINISH_POLYGON_DRAWING",
          "label/BEGIN_INFERENCE",
          "label/ESCAPE_KEY_HIT",
          "label/BATCH_OCR_START",
          "label/INFERENCE_UPDATE_FIGURE",
          "label/INFERENCE_UPDATE_FIGURE_WITH_MULTIPLE_LINES",
          "label/FINISH_INFERENCE",
        ].includes(action.type)
      ) {
        return "NEW_USER_INFERENCE-" + currentState.uuidForHistory;
      }
      if (
        ["label/START_MODEL_INFERENCE", "label/APPLY_MODEL_INFERENCE_RESULTS", "label/END_MODEL_INFERENCE"].includes(
          action.type
        )
      ) {
        return "NEW_MODEL_INFERENCE-" + currentState.uuidForHistory;
      }
      if (
        ["label/FIGURE_ENTRY_BEGIN", "label/FIGURE_ENTRY_TEXT_CHANGE", "label/FIGURE_ENTRY_COMMIT_CHANGE"].includes(
          action.type
        )
      ) {
        return "FIGURE_ENTRY_TEXT_CHANGE-" + currentState.uuidForHistory;
      }
      if (["label/SWITCH_AUTO_SAVE"].includes(action.type)) {
        return "SWITCH_AUTO_SAVE";
      }
      if (["label/SWITCH_AUTO_SPLIT"].includes(action.type)) {
        return "SWITCH_AUTO_SPLIT";
      }
      if (["label/SWITCH_TEMPLATE_ZOOM"].includes(action.type)) {
        return "SWITCH_TEMPLATE_ZOOM";
      }

      return null;
    },
    // syncFilter: true,
    // debug: true
  }),

  templateActivityState: undoable(templateActivityStateReducer, {
    undoType: "template/UNDO",
    redoType: "template/REDO",
    filter: excludeAction([
      "template/SUBMISSION_BEGIN",
      "template/SUBMISSION_SUCCESS_UPDATE",
      "template/SUBMISSION_FINISH",
      "template/INCREMENTAL_SUBMISSION_BEGIN",
      "template/INCREMENTAL_SUBMISSION_SUCCESS_UPDATE",
      "template/INCREMENTAL_SUBMISSION_FINISH",
      "template/SUBMISSION_ERROR",
      "template/RESET_LABEL_PAGE",
      "template/RESET_ACTIVITY_ON_NO_IMAGE",
      "template/HANDLE_LEFT_SIDER_INIT",
      "template/UPDATE_LEFT_SIDER_PAGINATION",
      "template/CLICK_ON_VIEW",
      "template/CLICKED_ON_ADD_FIELD",
      "template/CLICKED_ON_FIGURE_IN_PANEL",
      "template/CLICKED_ON_VALUE_FIGURE_IN_PANEL",
      "template/CLICKED_ON_FIGURE",
      "template/CLICKED_ON_VALUE_FIGURE",
      "template/BEGIN_MULTI_SELECT",
      "template/MULTI_SELECT_FIGURES",
      "template/ADD_INIT_LABEL_DATA",
      "template/SWITCH_PAGE",
      "template/GOTO_ADD_PAGE",
      "template/LABEL_UNDO_REDO_IMAGE_UPDATED",
      "template/COPY_FIGURES",
      "template/MOVE_START",
      "template/SELECT_ALL_FIGURES",
    ]),
    groupBy: (action, currentState, previousHistory) => {
      if (
        [
          "template/ADD_POINT_TO_UNFINISHED_FIGURE",
          "template/MOVED_BBOX_POINT",
          "template/MOVED_POLYGON_POINT",
          "template/FINISH_BBOX_DRAWING",
          "template/FINISH_POLYGON_DRAWING",
          "template/VALUE_MOVED_BBOX_POINT",
          "template/VALUE_MOVED_POLYGON_POINT",
          "template/VALUE_FINISH_BBOX_DRAWING",
          "template/VALUE_FINISH_POLYGON_DRAWING",
          "template/BEGIN_INFERENCE",
          "template/ESCAPE_KEY_HIT",
          "template/BATCH_OCR_START",
          "template/INFERENCE_UPDATE_FIGURE",
          "template/INFERENCE_UPDATE_FIGURE_WITH_MULTIPLE_LINES",
          "template/FINISH_INFERENCE",
        ].includes(action.type)
      ) {
        return "NEW_USER_INFERENCE-" + currentState.uuidForHistory;
      }
      if (
        [
          "template/START_MODEL_INFERENCE",
          "template/APPLY_MODEL_INFERENCE_RESULTS",
          "template/END_MODEL_INFERENCE",
        ].includes(action.type)
      ) {
        return "NEW_MODEL_INFERENCE-" + currentState.uuidForHistory;
      }
      if (
        [
          "template/FIGURE_ENTRY_BEGIN",
          "template/FIGURE_ENTRY_TEXT_CHANGE",
          "template/FIGURE_ENTRY_COMMIT_CHANGE",
        ].includes(action.type)
      ) {
        return "FIGURE_ENTRY_TEXT_CHANGE-" + currentState.uuidForHistory;
      }
      if (["template/SWITCH_AUTO_SAVE"].includes(action.type)) {
        return "SWITCH_AUTO_SAVE";
      }
      if (["template/SWITCH_AUTO_SPLIT"].includes(action.type)) {
        return "SWITCH_AUTO_SPLIT";
      }

      return null;
    },
  }),
  fileState: FileStateReducer,
  task: TaskReducer,
  curModelTabKey: CurModelTabKeyReducer,
  regressionDetails: RegressionDetailsReducer,
  evalPreviewData: EvaluationPreviewDataReducer,
  evalPreviewModal: EvaluationPreviewModalReducer,
  evalPreviewImageId: EvaluationPreviewImageIDReducer,
  assignedModelInfo: AssignedModelReducer,
});

export { rootReducer };
