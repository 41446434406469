import API from '../../services/Schema';

const getSchemaData = classId => {
    return {
        type: 'GET_SCHEMA_DATA',
        payload: API.getSchemaData(classId),
    };
};

const getGroupSchemaData = classId => {
    return {
        type: 'GET_GROUP_SCHEMA_DATA',
        payload: API.getGroupSchema(classId)
    }
}

const schemaActions = {getSchemaData, getGroupSchemaData};
export default schemaActions;
