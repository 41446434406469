const setCurModelTabKey = key => {
    return {
        type: 'SET_CURRENT_MODEL_TAB',
        payload: key,
    }
}

const setRegressionDetails = res => {
    return {
        type: 'SET_REGRESSION_DETAIL',
        payload: res,
    }
}

const setEvalPreviewData = previewData => {
    return {
        type: 'SET_EVALUATION_PREVIEW_DATA',
        payload: previewData,
    }
}

const setEvalPreviewModal = display => {
    return {
        type: 'SET_EVALUATION_PREVIEW_MODAL',
        payload: display,
    }
}

const setEvalPreviewImageId = image_id => {
    return {
        type: 'SET_EVALUATION_PREVIEW_IMAGE_ID',
        payload: image_id,
    }
}

const getAssignedModel = (project_id, class_id, type, assigned_model_id, assigned_model_name, active_version) => {
    return {
        type: 'GET_ASSIGNED_MODEL_INFO',
        payload: {
            "project_id": project_id,
            "class_id": type === "detection" ? class_id : -1,
            "assigned_model_id": assigned_model_id,
            "assigned_model_name": assigned_model_name,
            "active_version": active_version,
        },
    }

}

const modelActions = {setCurModelTabKey, setRegressionDetails, setEvalPreviewData, setEvalPreviewModal, setEvalPreviewImageId, getAssignedModel};
export default modelActions;