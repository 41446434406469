import { FileDoneOutlined, FileOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import { produce } from "immer";
import _ from "lodash";
import { colors, group_colors, recursiveSearch } from "../../utils/util";

// If the size of incremental updates is greater than this number
// We might as well just full update
const INCREMENTAL_UPDATE_THRESHOLD = 20;

// variables needed for labeling activities
const initLabelActivityState = {
  // current image meta
  currentImage: {},
  // current "page" this image is out of all other images for pagination of view.js
  // this should be 0 indexed because it represents the position in array of filtered Images
  page: 0,
  // Selected items in directory view on leftside
  selectedKeys: [],
  // which folders are open in leftside
  expandedKeys: [],
  // Image rendering size
  imageSize: 1024,
  // full directory tree data
  treeData: [],
  // shallow copy of all images held in treeData for fast lookup
  images: [],
  // copy of images, holding filtered images for pagination in View
  filteredImages: [],
  // total number of labeled files
  labeled: 0,

  // a unique uuid to help make groups for redux-undo
  uuidForHistory: null,
  // Autosave status
  autoSave: true,
  // Whether or not the user has edited this image
  imageUpdated: false,
  // For autosave, if user edits while submitting, can lead to ghosted effects, so this needs to be here
  imageUpdatedWhileSaving: false,
  // Running inference initiates an async call that may update the page, so we want to prevent future bugs
  imageAboutToUpdate: false,

  // When an action that relates to a label is done, add it to this list
  // When auto save is on, we will save labels individually
  // however, there are some actions that affect a group of labels
  // we add these actions to fire off the normal save

  // array of type {
  //   type: string -> "update" | "delete",
  //   label: { figure_info... }
  // }
  incrementalUpdates: [],
  // simple true false to send a full update
  sendFullUpdate: false,

  // In order to not spam autosave when editing a label, we compare old/new text and only submit if changed
  oldFigureText: "",
  // rightsider toggle for inference
  autoSplit: true,
  // label data, technically, this is the schema, labelData[i].figures will contain the labels of schema's i
  labelData: [],
  // Selected polygon/bbox
  // Set to -1 to indicate no selection
  selectedFigureId: -1,
  // This list holds all the label ids that are selected, if multiple figures are selected, then selectedFigureId is reset to -1
  // When we do context menu actions, we will reference this list
  multipleFigureSelectionList: [],
  // Figure data, really just a copy of every labelData[i].figures
  figures: [],
  // Temporary clipboard for copying figures
  figureClipboard: {
    imageSize: 1024,
    cursorLatLng: {},
    figures: [],
  },
  // We store the cursor position before move so we can calculate diff
  moveData: {
    isMoving: false,
    cursorLatLng: {},
  },
  // Drawing mode, bbox or polygon
  drawingMode: "bbox",
  // Whether we're in label or group mode
  groupMode: false,
  // temporary data store for polygon/bbox user is making, will contain a list of points
  unfinishedFigure: null,
  // selected label id, as in schema entry
  // Set to -1 to indicate no selection
  selectedLabelId: -1,
  // Remember the last selected label id so we can reselect it
  lastSelectedLabelId: -1,
  // Status of image edit submission
  currentlySubmitting: false,
  // If the last submission error'd, this is so we don't get into an infinite loop
  submissionErrored: false,
  // Whether or not we are waiting for a rotate
  // This is important because rotation is handled on the backend,
  // frontend just renders data, so we need to save first, then rotate
  pendingRotate: false,
  // Store the currently selected template data, based off the currently selected image
  // From classId and subclassId, we can call an api to get the template id
  // then we can get the label positions
  // templateLabelData: [key_labels]
  templateData: {
    classId: null,
    subclassId: null,
    // templateId: null,
    templateLabelData: null,
  },
  // whether or not the template zoom feature is enabled
  templateZoom: false,

  // Selected group id, set when user clicks on subgroup in rightsider
  // [group id, subgroup id], id can potentially be idx
  // Set to [-1, -1] to indicate no selection
  selectedGroupId: [-1, -1],
  // Group data, groups -> subgroups -> labels
  groupData: [],
  // a reverse lookup table, map of label_id -> [groupId, subgroupId]
  reverseFigureLookup: {},
  // Color the figures based off schema or group?
  groupColors: false,
};

// Wrap your case in {} to enforce scope if you declare any variables
export const labelActivityStateReducer = produce((draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case "label/CHANGE_IMAGE_SIZE":
      {
        const safeToSwitch = !draft.imageUpdated && !draft.imageAboutToUpdate;
        if (safeToSwitch) {
          draft.imageSize = payload;
        } else {
          if (draft.currentlySubmitting) {
            message.warn("Please wait for saving to complete");
          } else {
            message.warn("Please save the current labels first");
          }
        }
      }
      break;
    case "label/SET_CURRENT_IMAGE":
      // weird action called by dataperfection and eval detail
      // NEEDS TO BE REDONE
      draft.imageSize = 1024;
      draft.currentImage = payload;
      draft.selectedKeys = [payload.image_id];
      draft.expandedKeys = [payload.parentKey];
      break;
    case "label/PERFECTION_SET_TREE":
      // weird action called by perfection
      draft.treeData = payload;
      break;
    case "label/UPDATE_LEFT_SIDER_PAGINATION":
      // correlates the clicked filename id with the page in pagination
      if (draft.currentImage && Object.keys(draft.currentImage).length > 0 && draft.filteredImages.length > 0) {
        let i = 0;
        for (; i < draft.filteredImages.length; i++) {
          if (draft.filteredImages[i].image_id === draft.currentImage.image_id) {
            draft.page = i;
            break;
          }
        }
        if (i === draft.filteredImages.length) {
          draft.page = 0;
          draft.currentImage = {};
        }
      }
      break;
    case "label/UPDATE_FILTERED_IMAGES":
      draft.filteredImages = payload;
      break;
    case "label/HANDLE_LEFT_SIDER_INIT":
      {
        const classes = payload.classes;
        let tree = [],
          imagesArray = [];
        // Since we're reloading data, unset current image
        draft.currentImage = null;
        draft.treeData = [];
        draft.images = [];

        Object.keys(classes).forEach((classId) => {
          let subTree = [];

          Object.keys(classes[classId].subclasses).forEach((subclassId) => {
            let docSubTree = [];

            // the subclassId: string of "null" are for images that are assigned to no subclass
            // the rest are numbers
            if (subclassId !== "null") {
              const subclass = classes[classId].subclasses[subclassId];

              Object.keys(subclass.documents).forEach((documentId) => {
                const document = subclass.documents[documentId];
                if (documentId !== "null") {
                  const images = document.images.map((image) => {
                    image.parentKey = `${classId}-${subclassId}-${documentId}`;

                    if (!draft.currentImage) {
                      draft.currentImage = image;
                      draft.templateData.classId = classId;
                      draft.templateData.subclassId = subclassId;
                    }

                    const temp = {
                      title: (
                        <Tooltip placement="topLeft" title={image.name}>
                          <span style={{ color: image.labelled ? "#52c41a" : "inherit" }}>
                            {image.page_number ? "Page " + image.page_number : image.name}
                          </span>
                        </Tooltip>
                      ),
                      key: image.image_id,
                      value: image.image_id,
                      image_id: image.image_id,
                      classId: classId,
                      subclassId: subclassId,
                      name: image.name,
                      isLeaf: true,
                      icon: image.labelled && <FileDoneOutlined style={{ color: "#52c41a" }} />,
                      labelled: image.labelled,
                      has_groups: image.has_groups,
                      dataset: image.dataset,
                      parentKey: `${classId}-${subclassId}-${documentId}`,
                    };

                    return temp;
                  });

                  imagesArray = imagesArray.concat(images);

                  docSubTree.push({
                    title: document.name,
                    key: `${classId}-${subclassId}-${documentId}`,
                    value: `${classId}-${subclassId}-${documentId}`,
                    isLeaf: false,
                    children: images,
                  });
                } else if (documentId === "null") {
                  // null indicates that these images were uploaded alone, not from a document pdf
                  const images = document.images.map((image) => {
                    image.parentKey = `${classId}-${subclassId}`;

                    if (!draft.currentImage) {
                      draft.currentImage = image;
                      draft.templateData.classId = classId;
                      draft.templateData.subclassId = subclassId;
                    }

                    const temp = {
                      title: (
                        <Tooltip placement="topLeft" title={image.name}>
                          <span style={{ color: image.labelled ? "#52c41a" : "inherit" }}>{image.name}</span>
                        </Tooltip>
                      ),
                      key: image.image_id,
                      value: image.image_id,
                      image_id: image.image_id,
                      classId: classId,
                      subclassId: subclassId,
                      name: image.name,
                      isLeaf: true,
                      icon: image.labelled && <FileDoneOutlined style={{ color: "#52c41a" }} />,
                      labelled: image.labelled,
                      has_groups: image.has_groups,
                      dataset: image.dataset,
                      parentKey: `${classId}-${subclassId}`,
                    };

                    return temp;
                  });
                  imagesArray = imagesArray.concat(images);
                  docSubTree = docSubTree.concat(images);
                }
              });

              subTree.push({
                title: subclass.name,
                key: `${classId}-${subclassId}`,
                value: `${classId}-${subclassId}`,
                isLeaf: false,
                children: docSubTree,
              });
            } else if (subclassId === "null") {
              const subclass = classes[classId].subclasses[subclassId];

              Object.keys(subclass.documents).forEach((documentId) => {
                const document = subclass.documents[documentId];

                if (documentId !== "null") {
                  const images = document.images.map((image) => {
                    image.parentKey = `${classId}-${classes[classId].name}-${documentId}`;

                    if (!draft.currentImage) {
                      draft.currentImage = image;
                      draft.templateData.classId = classId;
                      draft.templateData.subclassId = subclassId;
                    }

                    const temp_image = {
                      title: (
                        <Tooltip placement="topLeft" title={image.name}>
                          <span style={{ color: image.labelled ? "#52c41a" : "inherit" }}>
                            {image.page_number ? "Page " + image.page_number : image.name}
                          </span>
                        </Tooltip>
                      ),
                      key: image.image_id,
                      value: image.image_id,
                      image_id: image.image_id,
                      isLeaf: true,
                      icon: image.labelled && <FileDoneOutlined style={{ color: "#52c41a" }} />,
                      classId: classId,
                      subclassId: null,
                      name: image.name,
                      labelled: image.labelled,
                      has_groups: image.has_groups,
                      dataset: image.dataset,
                      parentKey: `${classId}-${classes[classId].name}-${documentId}`,
                    };

                    return temp_image;
                  });

                  imagesArray = imagesArray.concat(images);

                  subTree.push({
                    title: document.name,
                    key: `${classId}-${classes[classId].name}-${documentId}`,
                    value: `${classId}-${classes[classId].name}-${documentId}`,
                    isLeaf: false,
                    children: images,
                  });
                } else if (documentId === "null") {
                  const images = document.images.map((image) => {
                    image.parentKey = `${classId}-${classes[classId].name}`;

                    if (!draft.currentImage) {
                      draft.currentImage = image;
                      draft.templateData.classId = classId;
                      draft.templateData.subclassId = subclassId;
                    }

                    const temp_image = {
                      title: (
                        <Tooltip placement="topLeft" title={image.name}>
                          <span style={{ color: image.labelled ? "#52c41a" : "inherit" }}>{image.name}</span>
                        </Tooltip>
                      ),
                      key: image.image_id,
                      value: image.image_id,
                      image_id: image.image_id,
                      isLeaf: true,
                      icon: image.labelled && <FileDoneOutlined style={{ color: "#52c41a" }} />,
                      classId: classId,
                      subclassId: null,
                      name: image.name,
                      labelled: image.labelled,
                      has_groups: image.has_groups,
                      dataset: image.dataset,
                      parentKey: `${classId}-${classes[classId].name}`,
                    };

                    return temp_image;
                  });

                  imagesArray = imagesArray.concat(images);

                  subTree = subTree.concat(images);
                }
              });
            }
          });

          tree.push({
            title: classes[classId].name,
            key: `${classId}-${classes[classId].name}`,
            value: `${classId}-${classes[classId].name}`,
            isLeaf: false,
            children: subTree,
          });
        });

        // This makes a shallow copy of each image metadata, allowing for quick lookups
        draft.images = imagesArray.filter((item) => item.isLeaf === true);
        draft.treeData = tree;

        if (draft.currentImage) {
          draft.selectedKeys = [draft.currentImage.image_id];
          draft.expandedKeys = [draft.currentImage.parentKey];
        }
        draft.labeled = payload.finished;
      }
      break;
    case "label/DISREGARD_EDITS":
      // used right before user switches pages when auto save is off
      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      break;
    case "label/SWITCH_PAGE":
      const safeToSwitch = !draft.imageUpdated && !draft.imageAboutToUpdate;
      if (safeToSwitch) {
        draft.page = _.clamp(payload.pageIdx, 0, draft.filteredImages.length - 1);
        draft.currentImage = draft.filteredImages[draft.page];
        // reset template label data if class/subclass changed
        if (
          draft.templateData.classId !== draft.currentImage.classId ||
          draft.templateData.subclassId !== draft.currentImage.subclassId
        ) {
          draft.templateData.classId = draft.currentImage.classId;
          draft.templateData.subclassId = draft.currentImage.subclassId;
          draft.templateData.templateLabelData = null;
        }
        draft.selectedKeys = [draft.currentImage.image_id];
        draft.expandedKeys = [draft.currentImage.parentKey];
      } else {
        message.warn("Please wait for saving to complete");
      }

      // The "save?" check happens elsewhere, so we can safely discard all changes here
      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      break;
    case "label/SET_TEMPLATE_LABELDATA":
      draft.templateData.templateLabelData = payload.templateLabelData;
      break;
    case "label/SET_EXPANDED_KEYS":
      draft.expandedKeys = payload;
      break;
    case "label/DESELECT_IMAGE":
      draft.page = 0;
      draft.currentImage = {};
      break;
    case "label/RESET_ACTIVITY_ON_IMAGE_CHANGE":
      draft.selectedKeys = [];
      break;
    case "label/RESET_LABEL_PAGE":
      return initLabelActivityState;
    case "label/ESCAPE_KEY_HIT":
      // Don't unselect selection on cancel move
      if (draft.moveData.isMoving) {
        draft.moveData.isMoving = false;
      } else {
        draft.unfinishedFigure = null;
        draft.selectedLabelId = -1;
        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
        draft.selectedGroupId = [-1, -1];
      }
      break;
    case "label/RESET_ACTIVITY_ON_NO_IMAGE":
      draft.imageUpdated = false;
      draft.imageUpdatedWhileSaving = false;
      draft.figures = [];
      draft.labelData = [];
      draft.unfinishedFigure = null;
      draft.selectedLabelId = -1;
      draft.currentlySubmitting = false;
      draft.pendingRotate = false;
      draft.templateData = structuredClone(initLabelActivityState.templateData);
      break;
    case "label/INFERENCE_UPDATE_FIGURE":
      {
        // we will update figures alongside labelData internal figures
        let figure = payload.figure;
        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["id", figure.id]);
          draft.figures.splice(index, 1);
        }

        let figureCopy = _.cloneDeep(figure);
        const {
          ocr_result: { confidence_score, pred },
        } = payload.resultList[0];
        figureCopy.user_result = pred;
        figureCopy.ocr_score = confidence_score;

        draft.figures.push(figureCopy);

        // update labelData
        const i = _.findIndex(draft.labelData, ["id", figure.schema_id]);
        const index = _.findIndex(draft.labelData[i].figures, ["id", figure.id]);

        // overwrite old figure with new one
        if (payload.redrawing && index !== -1) {
          draft.labelData[i].figures[index] = figureCopy;
        } else {
          draft.labelData[i].figures.push(figureCopy);
        }

        // auto scroll to the new figure
        draft.selectedFigureId = figure.id;
        // auto select it
        draft.multipleFigureSelectionList = [draft.selectedFigureId];

        // send the new figure to be saved
        // a single result will result in a one to one update
        // no need to delete old box if redrawing
        draft.incrementalUpdates.push({
          type: "update",
          label: figureCopy,
        });
      }
      break;
    case "label/INFERENCE_UPDATE_FIGURE_WITH_MULTIPLE_LINES":
      {
        const detectedFigureList = payload.detectedFigureList;
        const deleteFigureId = payload.deleteFigureId;

        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["id", deleteFigureId]);
          // when redrawing a one to many result, we have to delete the old figure
          // this also means we need to remove it from the group it was in

          if (deleteFigureId in draft.reverseFigureLookup) {
            let figure_group = draft.reverseFigureLookup[deleteFigureId];
            let groupIdx = figure_group[0];
            let subgroupIdx = figure_group[1];
            draft.groupData[groupIdx].labels[subgroupIdx].label_ids = draft.groupData[groupIdx].labels[
              subgroupIdx
            ].label_ids.filter((id) => id !== deleteFigureId);

            delete draft.reverseFigureLookup[deleteFigureId];
          }

          draft.incrementalUpdates.push({
            type: "delete",
            label: draft.figures[index],
          });
          draft.figures.splice(index, 1);
        }

        draft.figures = draft.figures.concat(detectedFigureList);

        // Now do same updates for labelData
        const i = _.findIndex(draft.labelData, ["id", detectedFigureList[0].schema_id]);

        // delete previous figure schema if redrawing bbox
        if (payload.redrawing) {
          const index = _.findIndex(draft.labelData[i].figures, ["id", deleteFigureId]);
          draft.labelData[i].figures.splice(index, 1);
        }

        draft.multipleFigureSelectionList = [];

        detectedFigureList.forEach((figure) => {
          draft.labelData[i].figures.push(figure);
          // autoscroll to last new label
          draft.selectedFigureId = figure.id;
          draft.multipleFigureSelectionList = [figure.id];
          // send each new figure to be saved
          draft.incrementalUpdates.push({
            type: "update",
            label: figure,
          });
        });
      }
      break;
    case "label/INFERENCE_ADD_NON_OCR_FIGURE":
      {
        let figure = payload.figure;
        // draft.imageUpdated = true
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        // instead of an inference result, we just append an empty figure/label
        if (payload.redrawing) {
          const index = _.findIndex(draft.figures, ["id", figure.id]);
          draft.figures.splice(index, 1);
        }

        let figureCopy = _.cloneDeep(figure);
        figureCopy.user_result = "";

        draft.figures.push(figureCopy);

        // update labelData
        const i = _.findIndex(draft.labelData, ["id", figure.schema_id]);
        const index = _.findIndex(draft.labelData[i].figures, ["id", figure.id]);

        if (payload.redrawing && index !== -1) {
          draft.labelData[i].figures[index] = figureCopy;
        } else {
          draft.labelData[i].figures.push(figureCopy);
        }

        // auto scroll to the new figure
        draft.selectedFigureId = figure.id;
        draft.multipleFigureSelectionList = [figure.id];

        // save new figure
        draft.incrementalUpdates.push({
          type: "update",
          label: figure,
        });
      }
      break;
    case "label/FINISH_INFERENCE":
      draft.imageUpdated = true;
      draft.imageAboutToUpdate = false;
      draft.selectedLabelId = -1;
      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }
      // draft.selectedFigureId = -1
      break;
    case "label/SWITCH_DRAWING_MODE":
      draft.drawingMode = draft.drawingMode === "bbox" ? "polygon" : "bbox";

      if (draft.selectedLabelId !== -1) {
        let selectedLabel = draft.labelData.find((x) => x.id === draft.selectedLabelId);

        draft.unfinishedFigure = {
          id: null,
          color: selectedLabel.color,
          type: draft.drawingMode,
          points: [],
          schema_id: draft.selectedLabelId,
        };
      } else {
        draft.unfinishedFigure = null;
      }
      break;
    case "label/SWITCH_TEMPLATE_ZOOM":
      draft.templateZoom = !draft.templateZoom;
      break;
    case "label/CLEAR_LABELS":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      draft.figures = [];
      draft.labelData.forEach((x) => (x.figures = []));

      draft.reverseFigureLookup = {};
      draft.groupData.forEach((group) => {
        group.labels = [];
      });

      // this is an action that should be batch auto saved
      draft.sendFullUpdate = true;
      break;
    case "label/START_MODEL_INFERENCE":
      draft.imageAboutToUpdate = true;
      draft.uuidForHistory = payload.uuid;
      break;
    // this is when you click apply model
    case "label/APPLY_MODEL_INFERENCE_RESULTS":
      {
        // array of {labelDataIndex, figure}
        let newFigures = payload.newFigures;

        newFigures.forEach((f) => {
          draft.labelData[f.index].figures.push(f.figure);
          draft.figures.push(f.figure);
        });

        // applying model usually sends back a huge number of labels, so we might as well batch save
        draft.sendFullUpdate = true;
      }
      break;
    case "label/END_MODEL_INFERENCE":
      draft.imageUpdated = true;
      draft.imageAboutToUpdate = false;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      break;
    case "label/CLEAR_SUBGROUPS":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      draft.groupData.forEach((x) => (x.labels = []));
      draft.reverseFigureLookup = {};
      draft.selectedGroupId = [-1, -1];

      // this clears a lot of stuff so batch save
      draft.sendFullUpdate = true;
      break;
    case "label/TOGGLE_LABEL_GROUP_MODE":
      draft.groupMode = !draft.groupMode;
      draft.unfinishedFigure = null;
      draft.selectedLabelId = -1;
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];

      draft.moveData.isMoving = false;

      draft.selectedGroupId = [-1, -1];
      draft.groupColors = false;
      break;
    case "label/CLICKED_ON_SUBGROUP":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      draft.unfinishedFigure = null;

      draft.moveData.isMoving = false;

      draft.selectedGroupId = [payload.groupIdx, payload.subgroupIdx];
      break;
    case "label/GROUP_MODE_CLICK_ON_FIGURE_ENTRY":
      if (payload.multiple) {
        draft.selectedFigureId = payload.figureId;
        if (!draft.multipleFigureSelectionList.includes(payload.figureId)) {
          draft.multipleFigureSelectionList.push(payload.figureId);
        }
      } else {
        draft.selectedFigureId = payload.figureId;
        draft.multipleFigureSelectionList = [payload.figureId];
      }
      draft.unfinishedFigure = null;

      draft.moveData.isMoving = false;

      draft.selectedGroupId = [-1, -1];
      break;
    case "label/CHANGE_GROUP_EXPAND":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];

      draft.groupData[payload.groupIdx].expand = !draft.groupData[payload.groupIdx].expand;
      // deselect subgroup if group collapsed
      if (payload.groupIdx === draft.selectedGroupId[0] && !draft.groupData[payload.groupIdx].expand) {
        draft.selectedGroupId = [-1, -1];
      }
      break;
    case "label/FIGURE_ENTRY_BEGIN":
      draft.oldFigureText = payload.text;
      draft.uuidForHistory = payload.uuid;
      break;
    case "label/FIGURE_ENTRY_TEXT_CHANGE":
      // We will update imageUpdated once user unfocuses textinput or hits enter
      // draft.imageUpdated = true
      draft.labelData[payload.labelIndex].figures[payload.figureIndex].user_result = payload.text;
      break;
    case "label/FIGURE_ENTRY_COMMIT_CHANGE":
      if (draft.oldFigureText !== payload.text) {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      }
      draft.oldFigureText = "";

      // send individual update
      draft.incrementalUpdates.push({
        type: "update",
        label: draft.labelData[payload.labelIndex].figures[payload.figureIndex],
      });
      break;
    case "label/ADD_NEW_SUBGROUP":
      {
        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];

        draft.groupData[payload].labels.push({
          label_ids: [],
          expand: true,
        });
        // select the last subgroup in the list, which is the newly added one
        draft.selectedGroupId = [payload, draft.groupData[payload].labels.length - 1];
      }
      break;
    case "label/ADD_LABEL_TO_SUBGROUP":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];

      // ocr figures only have an id, and not label_id
      if (!!payload.figure.label_id) {
        // only allow a label to be in one subgroup at a time
        if (payload.figure.label_id in draft.reverseFigureLookup) {
          message.warn(
            "This label already is part of " +
              draft.groupData[payload.groupIdx].collection_key +
              " grouping " +
              (payload.subgroupIdx + 1)
          );
        } else {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

          draft.groupData[payload.groupIdx].labels[payload.subgroupIdx].label_ids.push(payload.figure.label_id);
          draft.reverseFigureLookup[payload.figure.label_id] = [payload.groupIdx, payload.subgroupIdx];

          draft.incrementalUpdates.push({
            type: "update",
            label: payload.figure,
          });
        }
      } else {
        message.warn("You must save this figure before assigning it to a group", 3);
      }
      break;
    case "label/REMOVE_LABEL_FROM_SUBGROUP":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      draft.groupData[payload.groupIdx].labels[payload.subgroupIdx].label_ids = draft.groupData[
        payload.groupIdx
      ].labels[payload.subgroupIdx].label_ids.filter((id) => id !== payload.label.label_id);
      delete draft.reverseFigureLookup[payload.label.label_id];
      draft.selectedGroupId = [-1, -1];

      draft.incrementalUpdates.push({
        type: "update",
        label: payload.label,
      });
      break;
    case "label/REMOVE_SUBGROUP":
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      draft.groupData[payload.groupIdx].labels.splice(payload.subgroupIdx, 1);
      for (const [key, value] of Object.entries(draft.reverseFigureLookup)) {
        if (value[0] === payload.groupIdx && value[1] === payload.subgroupIdx) {
          delete draft.reverseFigureLookup[key];
        }
      }

      draft.selectedGroupId = [-1, -1];

      // this could possibly be a batch operation
      draft.sendFullUpdate = true;
      break;
    case "label/DELETE_FIGURE_FROM_RIGHT_SIDER":
      {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
        draft.labelData[payload.labelIndex].figures.splice(payload.figureIndex, 1);
        const index = _.findIndex(draft.figures, ["id", payload.figureId]);

        draft.incrementalUpdates.push({
          type: "delete",
          label: draft.figures[index],
        });

        draft.figures.splice(index, 1);

        if (payload.figureId in draft.reverseFigureLookup) {
          const g = draft.reverseFigureLookup[payload.figureId];
          const groupIdx = g[0];
          const subgroupIdx = g[1];

          const labelIdx = draft.groupData[groupIdx].labels[subgroupIdx].label_ids.findIndex(
            (x) => x === payload.figureId
          );
          draft.groupData[groupIdx].labels[subgroupIdx].label_ids.splice(labelIdx, 1);
        }

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
      }
      break;
    case "label/CHANGE_FIELD_EXPAND":
      draft.labelData[payload.fieldIdx].expand = !draft.labelData[payload.fieldIdx].expand;
      break;
    case "label/CLICKED_ON_FIELD":
      {
        if (payload === -1) {
          break;
        }

        draft.selectedLabelId = payload;
        draft.lastSelectedLabelId = draft.selectedLabelId;
        let selectedLabel = draft.labelData.find((x) => x.id === payload);
        draft.unfinishedFigure = {
          id: null,
          color: selectedLabel.color,
          type: draft.drawingMode,
          points: [],
          schema_id: draft.selectedLabelId,
        };

        draft.moveData.isMoving = false;

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
      }
      break;
    case "label/SELECT_PREVIOUS_FIELD":
      {
        const index = draft.labelData.findIndex((x) => x.id === draft.selectedLabelId);
        if (index !== -1 && index > 0) draft.selectedLabelId = draft.labelData[index - 1].id;
        if (index === -1) draft.selectedLabelId = draft.labelData[draft.labelData.length - 1].id;
        draft.lastSelectedLabelId = draft.selectedLabelId;

        let selectedLabel = draft.labelData.find((x) => x.id === draft.selectedLabelId);
        draft.unfinishedFigure = {
          id: null,
          color: selectedLabel.color,
          type: draft.drawingMode,
          points: [],
          schema_id: draft.selectedLabelId,
        };

        draft.moveData.isMoving = false;

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
      }
      break;
    case "label/SELECT_NEXT_FIELD":
      {
        const index = draft.labelData.findIndex((x) => x.id === draft.selectedLabelId);
        if (index !== -1 && index + 1 < draft.labelData.length) draft.selectedLabelId = draft.labelData[index + 1].id;
        if (index === -1) draft.selectedLabelId = draft.labelData[0].id;
        draft.lastSelectedLabelId = draft.selectedLabelId;

        let selectedLabel = draft.labelData.find((x) => x.id === draft.selectedLabelId);
        draft.unfinishedFigure = {
          id: null,
          color: selectedLabel.color,
          type: draft.drawingMode,
          points: [],
          schema_id: draft.selectedLabelId,
        };

        draft.moveData.isMoving = false;

        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
      }
      break;
    case "label/CLICKED_ON_FIGURE":
      draft.selectedFigureId = payload.figureId;
      draft.multipleFigureSelectionList = [payload.figureId];

      draft.unfinishedFigure = null;
      break;
    case "label/CLICKED_ON_FIGURE_IN_PANEL":
      if (payload.multiple) {
        draft.selectedFigureId = payload.figureId;
        if (!draft.multipleFigureSelectionList.includes(payload.figureId)) {
          draft.multipleFigureSelectionList.push(payload.figureId);
        }
      } else {
        draft.selectedFigureId = payload.figureId;
        draft.multipleFigureSelectionList = [payload.figureId];
      }
      draft.selectedLabelId = -1;
      draft.unfinishedFigure = null;
      break;
    case "label/BEGIN_MULTI_SELECT":
      draft.unfinishedFigure = null;
      break;
    case "label/MULTI_SELECT_FIGURES":
      draft.selectedFigureId = -1;
      // only add new figures, and don't double add
      payload.figureIds.forEach((figureId) => {
        if (!draft.multipleFigureSelectionList.includes(figureId)) {
          draft.multipleFigureSelectionList.push(figureId);
        }
      });
      break;
    case "label/BEGIN_INFERENCE":
      // draft.selectedLabelId = -1
      draft.imageAboutToUpdate = true;
      break;
    case "label/FINISH_BBOX_DRAWING":
    case "label/FINISH_POLYGON_DRAWING":
      // don't set updated here because we'll do inference right after
      // draft.imageUpdated = true
      // Inference auto starts after this, which should update figures for us
      // draft.figures.push(payload)
      draft.unfinishedFigure = null;
      break;
    case "label/DELETED_POLYGON_POINT":
    case "label/MOVED_BBOX_POINT":
    case "label/MOVED_POLYGON_POINT":
    case "label/SUBDIVIDE_POLYGON_EDGE":
      {
        // don't set updated here because we'll do inference right after
        // draft.imageUpdated = true
        const figureIdx = draft.figures.findIndex((x) => x.id === payload.newFigure.id);
        draft.figures[figureIdx] = payload.newFigure;
        draft.uuidForHistory = payload.uuid;
      }
      break;
    case "label/RESET_LABEL_ORDER":
      draft.labelData = [];
      break;
    case "label/ADD_POINT_TO_UNFINISHED_FIGURE":
      draft.unfinishedFigure.points.push(payload.point);
      draft.uuidForHistory = payload.uuid;
      break;
    case "label/CLICK_ON_VIEW":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      break;
    case "label/ADD_INIT_LABEL_DATA":
      {
        const labels = payload.labels;
        let schema = payload.schema;
        const image_id = payload.image_id;

        draft.labelData = [];
        draft.figures = [];
        draft.selectedFigureId = -1;
        draft.multipleFigureSelectionList = [];
        draft.selectedLabelId = -1;

        // sort schema by order
        let uniq_schema = _.unionBy(schema, "order");
        if (uniq_schema.length > 1) {
          schema = _.sortBy(schema, ["order"]);
        }

        for (let i = 0; i < schema.length; i++) {
          let figures = labels[schema[i].schema_id]
            ? labels[schema[i].schema_id].map((item) => {
                let new_point = item.points.map((point) => {
                  return {
                    lng: point.x,
                    lat: point.y,
                  };
                });
                return {
                  ...item,
                  points: new_point,
                  type: item.type,
                  id: item.label_id,
                  schema_id: schema[i].schema_id,
                  color: colors[i % colors.length],
                };
              })
            : [];
          draft.figures = draft.figures.concat(figures);

          draft.labelData.push({
            score: labels[schema[i].schema_id]?.some((item) => item.score < 0.9) ? -1 : 1,
            id: schema[i].schema_id,
            name: schema[i].name,
            property: schema[i].property ? schema[i].property.replace("-", "") : "text",
            figures,
            image_id: image_id,
            expand: true,
            color: colors[i % colors.length],
          });
        }

        draft.labelData.sort((a, b) => a.score - b.score);

        draft.selectedGroupId = [-1, -1];
        draft.reverseFigureLookup = {};
        draft.groupData = payload.group_data;
        // populate reverseFigureLookup as well, and expand everything at same time
        draft.groupData.forEach((group, groupIdx) => {
          group.color = group_colors[groupIdx % group_colors.length];
          group.expand = true;
          group.labels.forEach((subgroup, subgroupIdx) => {
            subgroup.expand = true;
            subgroup.label_ids.forEach((l) => {
              draft.reverseFigureLookup[l] = [groupIdx, subgroupIdx];
            });
          });
        });
      }
      break;
    case "label/REQUEST_ROTATE_IMAGE":
      draft.pendingRotate = true;
      break;
    case "label/ROTATION_FINISHED":
      draft.pendingRotate = false;
      // if user decides to not save before rotating
      draft.imageUpdated = false;
      break;
    // When user hits delete on keyboard when figure is selected
    // we will loop over every id selected
    case "label/REQUEST_FIGURE_DELETE":
      draft.multipleFigureSelectionList.forEach((figureId) => {
        draft.imageUpdated = true;
        if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

        const figureIdx = _.findIndex(draft.figures, ["id", figureId]);
        const figureSchemaId = draft.figures[figureIdx].schema_id;

        draft.incrementalUpdates.push({
          type: "delete",
          label: draft.figures[figureIdx],
        });

        // remove figure from screen
        draft.figures.splice(figureIdx, 1);
        // remove it from labeldata
        const labelIndex = _.findIndex(draft.labelData, ["id", figureSchemaId]);
        if (labelIndex > -1) {
          const ldFigureIdx = _.findIndex(draft.labelData[labelIndex].figures, ["id", figureId]);
          draft.labelData[labelIndex].figures.splice(ldFigureIdx, 1);
        }

        // now we need to update groups

        if (figureId in draft.reverseFigureLookup) {
          const g = draft.reverseFigureLookup[figureId];
          const groupIdx = g[0];
          const subgroupIdx = g[1];

          const labelIdx = draft.groupData[groupIdx].labels[subgroupIdx].label_ids.findIndex((x) => x === figureId);
          draft.groupData[groupIdx].labels[subgroupIdx].label_ids.splice(labelIdx, 1);
        }
      });

      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = [];
      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }

      break;
    case "label/SUBMISSION_BEGIN":
      draft.currentlySubmitting = true;
      draft.imageUpdatedWhileSaving = false;
      draft.submissionErrored = false;
      // if we are full saving, we don't need to keep any incremental updates
      draft.sendFullUpdate = false;
      draft.incrementalUpdates = [];
      break;
    case "label/SUBMISSION_SUCCESS_UPDATE":
      {
        // if the label page has been reset, just quit
        if (draft.currentImage.image_id) {
          if (!_.isEqual(draft.treeData, [])) {
            // if we're in eval detail edit mode, we don't have a left sider
            let trueImage = recursiveSearch(draft.treeData, draft.currentImage.image_id, "image_id");

            trueImage.icon =
              draft.figures.length > 0 ? <FileDoneOutlined style={{ color: "#52c41a" }} /> : <FileOutlined />;
            trueImage.title = (
              <Tooltip placement="topLeft" title={trueImage.name}>
                <span style={draft.figures.length > 0 ? { color: "#52c41a" } : {}}>{trueImage.name}</span>
              </Tooltip>
            );

            let add_flag = !trueImage.labelled && draft.figures.length > 0;
            let decrease_flag = draft.figures.length === 0 && trueImage.labelled;

            trueImage.labelled = draft.figures.length > 0;
            trueImage.has_groups = Object.keys(draft.reverseFigureLookup).length > 0;

            if (add_flag) {
              draft.labeled += 1;
            } else if (decrease_flag) {
              draft.labeled -= 1;
            }
          }

          for (const [new_pk, old_uuid] of Object.entries(payload.id_mapping || {})) {
            const oldFigure = _.find(draft.figures, ["label_uuid", old_uuid]);
            if (oldFigure) {
              // If a user deletes figures fast enough, API will not return fast enough
              const schemaIdx = _.findIndex(draft.labelData, ["id", oldFigure.schema_id]);

              const ld_figureIdx = _.findIndex(draft.labelData[schemaIdx].figures, ["label_uuid", old_uuid]);
              draft.labelData[schemaIdx].figures[ld_figureIdx].label_id = parseInt(new_pk);
              draft.labelData[schemaIdx].figures[ld_figureIdx].id = parseInt(new_pk);

              oldFigure.label_id = parseInt(new_pk);
              oldFigure.id = parseInt(new_pk);

              // remember to convert in selection list as well
              const mfslIdx = draft.multipleFigureSelectionList.indexOf(old_uuid);
              if (mfslIdx !== -1) {
                draft.multipleFigureSelectionList[mfslIdx] = parseInt(new_pk);
              }

              // convert selected figure id
              if (draft.selectedFigureId === old_uuid) {
                draft.selectedFigureId = parseInt(new_pk);
              }
            }
          }

          draft.imageUpdated = false;
        }
      }
      break;
    case "label/SUBMISSION_FINISH":
      draft.currentlySubmitting = false;
      break;
    case "label/SUBMISSION_ERROR":
      draft.submissionErrored = true;
      break;
    case "label/INCREMENTAL_SUBMISSION_BEGIN":
      draft.currentlySubmitting = true;
      draft.imageUpdatedWhileSaving = false;
      draft.submissionErrored = false;
      break;
    case "label/INCREMENTAL_SUBMISSION_SUCCESS_UPDATE":
      // if the label page has been reset, just quit
      if (draft.currentImage.image_id) {
        // res.label_uuid_map won't exist if we do a deletion
        if (payload.id_mapping !== undefined) {
          for (const [new_pk, old_uuid] of Object.entries(payload.id_mapping || {})) {
            const oldFigure = _.find(draft.figures, ["label_uuid", old_uuid]);
            if (oldFigure) {
              // If a user deletes figures fast enough, API will not return fast enough
              const schemaIdx = _.findIndex(draft.labelData, ["id", oldFigure.schema_id]);

              const ld_figureIdx = _.findIndex(draft.labelData[schemaIdx].figures, ["label_uuid", old_uuid]);
              draft.labelData[schemaIdx].figures[ld_figureIdx].label_id = parseInt(new_pk);
              draft.labelData[schemaIdx].figures[ld_figureIdx].id = parseInt(new_pk);

              oldFigure.label_id = parseInt(new_pk);
              oldFigure.id = parseInt(new_pk);

              // remember to convert in selection list as well
              const mfslIdx = draft.multipleFigureSelectionList.indexOf(old_uuid);
              if (mfslIdx !== -1) {
                draft.multipleFigureSelectionList[mfslIdx] = parseInt(new_pk);
              }

              // convert selected figure id
              if (draft.selectedFigureId === old_uuid) {
                draft.selectedFigureId = parseInt(new_pk);
              }
            }
          }
        }

        // now we need to remove the update from the list of pending updates to send to server
        let index = draft.incrementalUpdates.findIndex((update) => update.label.label_uuid === payload.label_uuid);
        draft.incrementalUpdates.splice(index, 1);
      }
      break;
    case "label/INCREMENTAL_SUBMISSION_FINISH":
      // only update left sider once
      if (draft.currentImage.image_id) {
        if (!_.isEqual(draft.treeData, [])) {
          // if we're in eval detail edit mode, we don't have a left sider
          let trueImage = recursiveSearch(draft.treeData, draft.currentImage.image_id, "image_id");

          trueImage.icon =
            draft.figures.length > 0 ? <FileDoneOutlined style={{ color: "#52c41a" }} /> : <FileOutlined />;
          trueImage.title = (
            <Tooltip placement="topLeft" title={trueImage.name}>
              <span style={draft.figures.length > 0 ? { color: "#52c41a" } : {}}>{trueImage.name}</span>
            </Tooltip>
          );

          let add_flag = !trueImage.labelled && draft.figures.length > 0;
          let decrease_flag = draft.figures.length === 0 && trueImage.labelled;

          trueImage.labelled = draft.figures.length > 0;
          trueImage.has_groups = Object.keys(draft.reverseFigureLookup).length > 0;

          if (add_flag) {
            draft.labeled += 1;
          } else if (decrease_flag) {
            draft.labeled -= 1;
          }
        }
      }
      draft.imageUpdated = false;
      draft.currentlySubmitting = false;
      break;
    case "label/SWITCH_AUTO_SPLIT":
      draft.autoSplit = !draft.autoSplit;
      break;
    case "label/SWITCH_AUTO_SAVE":
      draft.autoSave = !draft.autoSave;
      break;
    case "label/RESET_SELECTED_GROUP_ID":
      draft.selectedGroupId = [-1, -1];
      break;
    case "label/TOGGLE_GROUP_COLOR_MODE":
      draft.groupColors = !draft.groupColors;
      break;
    case "label/CHANGE_SUBGROUP_EXPAND":
      draft.groupData[payload.groupIdx].labels[payload.subgroupIdx].expand =
        !draft.groupData[payload.groupIdx].labels[payload.subgroupIdx].expand;
      // If this subgroup is selected and was collapsed, deselect it
      if (
        _.isEqual(draft.selectedGroupId, [payload.groupIdx, payload.subgroupIdx]) &&
        !draft.groupData[payload.groupIdx].labels[payload.subgroupIdx].expand
      ) {
        draft.selectedGroupId = [-1, -1];
      }
      break;
    case "label/CLICK_SHOW_ALL_LABELS_OF_GROUP":
      draft.selectedGroupId = [payload.groupIdx, -1];
      break;
    case "label/LABEL_UNDO_REDO_IMAGE_UPDATED":
      draft.imageUpdated = true;
      draft.sendFullUpdate = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
      break;
    case "label/COPY_FIGURES":
      // we have to check length of selection because user can call this via keybind
      if (draft.multipleFigureSelectionList.length > 0) {
        draft.figureClipboard.size = draft.imageSize;
        draft.figureClipboard.cursorLatLng = payload.cursorLatLng;
        draft.figureClipboard.figures = draft.figures.filter((figure) =>
          draft.multipleFigureSelectionList.includes(figure.id)
        );
      }
      break;
    case "label/PASTE_FIGURES":
      {
        if (payload.newFigures.length > 0) {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

          draft.multipleFigureSelectionList = [];

          payload.newFigures.forEach((figure) => {
            // for each new figure add it to figures array and labelData
            draft.figures.push(figure);

            const labelIndex = _.findIndex(draft.labelData, ["id", figure.schema_id]);
            if (labelIndex > -1) {
              draft.labelData[labelIndex].figures.push(figure);
            }

            // we can't update groups until labels are saved

            // select pasted figures
            draft.multipleFigureSelectionList.push(figure.id);

            // update incremental updates
            draft.incrementalUpdates.push({
              type: "update",
              label: figure,
            });
          });

          if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
            draft.sendFullUpdate = true;
          }
        }
      }
      break;
    case "label/ASSIGN_FIGURES_FIELD":
      {
        const matchingFigures = draft.figures.filter((figure) => draft.multipleFigureSelectionList.includes(figure.id));

        if (matchingFigures.length > 0) {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;
        }

        const schemaIdx = draft.labelData.findIndex((field) => field.id === payload.newFieldId);

        matchingFigures.forEach((matchingFigure) => {
          // create a new figure under the new schema id and assign new color
          const newFigure = {
            ...matchingFigure,
            schema_id: payload.newFieldId,
            color: colors[schemaIdx % colors.length],
          };

          // remove old figure from figures and add new figure
          const oldFigureIdx = draft.figures.findIndex((figure) => figure.id === matchingFigure.id);
          draft.figures.splice(oldFigureIdx, 1);

          draft.figures.push(newFigure);

          // remove old figure from old label data and add new figure to correct one
          const oldSchemaIdx = draft.labelData.findIndex((field) => field.id === matchingFigure.schema_id);
          const oldLDIdx = draft.labelData[oldSchemaIdx].figures.findIndex((figure) => figure.id === matchingFigure.id);
          draft.labelData[oldSchemaIdx].figures.splice(oldLDIdx, 1);

          draft.labelData[schemaIdx].figures.push(newFigure);

          // I don't think we need to remove labels from group data

          // update incrementally
          draft.incrementalUpdates.push({
            type: "update",
            label: newFigure,
          });
        });

        if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
          draft.sendFullUpdate = true;
        }
      }
      break;
    case "label/ASSIGN_FIGURES_GROUP":
      {
        if (draft.multipleFigureSelectionList.length > 0) {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

          const newGroupIdx = draft.groupData.findIndex((group) => group.collection_key === payload.newGroupId);
          // create new subgroup in group to place labels in if we need one
          let newSubgroupIdx = null;

          draft.multipleFigureSelectionList.forEach((figureId) => {
            // remove old figure ids from group data

            // if the figure is in group already do nothing
            // else add figure to new subgroup
            if (figureId in draft.reverseFigureLookup) {
              const oldGroupInfo = draft.reverseFigureLookup[figureId];
              if (oldGroupInfo[0] !== newGroupIdx) {
                // create new subgroup if needed
                if (newSubgroupIdx === null) {
                  newSubgroupIdx = draft.groupData[newGroupIdx].labels.length;
                  draft.groupData[newGroupIdx].labels.push({
                    label_ids: [],
                    expand: true,
                  });
                }

                // add to new subgroup
                draft.groupData[newGroupIdx].labels[newSubgroupIdx].label_ids.push(figureId);

                // remove from old subgroup
                const oldFigureIdx = draft.groupData[oldGroupInfo[0]].labels[oldGroupInfo[1]].label_ids.findIndex(
                  (id) => id === figureId
                );
                draft.groupData[oldGroupInfo[0]].labels[oldGroupInfo[1]].label_ids.splice(oldFigureIdx, 1);

                // update reverse figure lookup
                draft.reverseFigureLookup[figureId] = [newGroupIdx, newSubgroupIdx];

                // incremental update
                draft.incrementalUpdates.push({
                  type: "update",
                  label: draft.figures.find((figure) => figure.id === figureId),
                });
              }
            } else {
              // create new subgroup if needed
              if (newSubgroupIdx === null) {
                newSubgroupIdx = draft.groupData[newGroupIdx].labels.length;
                draft.groupData[newGroupIdx].labels.push({
                  label_ids: [],
                  expand: true,
                });
              }

              // add to new subgroup
              draft.groupData[newGroupIdx].labels[newSubgroupIdx].label_ids.push(figureId);

              // update reverse figure lookup
              draft.reverseFigureLookup[figureId] = [newGroupIdx, newSubgroupIdx];

              // incremental update
              draft.incrementalUpdates.push({
                type: "update",
                label: draft.figures.find((figure) => figure.id === figureId),
              });
            }
          });

          if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
            draft.sendFullUpdate = true;
          }
        }
      }
      break;
    case "label/ASSIGN_FIGURES_SUBGROUP":
      {
        if (draft.multipleFigureSelectionList.length > 0) {
          draft.imageUpdated = true;
          if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

          // id = idx for subgroup
          const newGroupIdx = draft.groupData.findIndex((group) => group.collection_key === payload.newGroupId);
          const newSubgroupIdx = payload.newSubgroupId;

          draft.multipleFigureSelectionList.forEach((figureId) => {
            if (figureId in draft.reverseFigureLookup) {
              const oldGroupInfo = draft.reverseFigureLookup[figureId];
              // skip if already in group
              if (oldGroupInfo[0] !== newGroupIdx && oldGroupInfo[1] !== newSubgroupIdx) {
                // add to new subgroup
                draft.groupData[newGroupIdx].labels[newSubgroupIdx].label_ids.push(figureId);

                // remove from old subgroup
                const oldFigureIdx = draft.groupData[oldGroupInfo[0]].labels[oldGroupInfo[1]].label_ids.findIndex(
                  (id) => id === figureId
                );
                draft.groupData[oldGroupInfo[0]].labels[oldGroupInfo[1]].label_ids.splice(oldFigureIdx, 1);

                // update reverse figure lookup
                draft.reverseFigureLookup[figureId] = [newGroupIdx, newSubgroupIdx];

                // incremental update
                draft.incrementalUpdates.push({
                  type: "update",
                  label: draft.figures.find((figure) => figure.id === figureId),
                });
              }
            } else {
              // add to new subgroup
              draft.groupData[newGroupIdx].labels[newSubgroupIdx].label_ids.push(figureId);

              // update reverse figure lookup
              draft.reverseFigureLookup[figureId] = [newGroupIdx, newSubgroupIdx];

              // incremental update
              draft.incrementalUpdates.push({
                type: "update",
                label: draft.figures.find((figure) => figure.id === figureId),
              });
            }
          });

          if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
            draft.sendFullUpdate = true;
          }
        }
      }
      break;
    case "label/BATCH_OCR_START":
      draft.uuidForHistory = payload.uuid;
      break;
    case "label/MOVE_START":
      draft.moveData.isMoving = true;
      draft.moveData.cursorLatLng = payload.cursorLatLng;
      break;
    case "label/MOVE_END":
      draft.moveData.isMoving = false;
      draft.imageUpdated = true;
      if (draft.currentlySubmitting) draft.imageUpdatedWhileSaving = true;

      const latDiff = payload.cursorLatLng.lat - draft.moveData.cursorLatLng.lat;
      const lngDiff = payload.cursorLatLng.lng - draft.moveData.cursorLatLng.lng;

      draft.multipleFigureSelectionList.forEach((figureId) => {
        const matchingFigure = draft.figures.find((figure) => figure.id === figureId);

        const newPoints = [];
        matchingFigure.points.forEach((point) => {
          newPoints.push({
            lat: point.lat + latDiff,
            lng: point.lng + lngDiff,
          });
        });

        // update figures and labelData
        // also delete ocr results

        const matchingFigureIdx = draft.figures.findIndex((figure) => figure.id === figureId);
        draft.figures[matchingFigureIdx].points = newPoints;
        draft.figures[matchingFigureIdx].user_result = "";
        draft.figures[matchingFigureIdx].ocr_result = null;
        delete draft.figures[matchingFigureIdx].ocr_score;
        delete draft.figures[matchingFigureIdx].det_score;

        const labelIndex = _.findIndex(draft.labelData, ["id", matchingFigure.schema_id]);
        const ldMatchingFigureIdx = draft.labelData[labelIndex].figures.findIndex((figure) => figure.id === figureId);
        draft.labelData[labelIndex].figures[ldMatchingFigureIdx].points = newPoints;
        draft.labelData[labelIndex].figures[ldMatchingFigureIdx].user_result = "";
        draft.labelData[labelIndex].figures[ldMatchingFigureIdx].ocr_result = null;
        delete draft.labelData[labelIndex].figures[ldMatchingFigureIdx].ocr_score;
        delete draft.labelData[labelIndex].figures[ldMatchingFigureIdx].det_score;

        // incremental updates
        draft.incrementalUpdates.push({
          type: "update",
          label: matchingFigure,
        });
      });

      if (draft.incrementalUpdates.length >= INCREMENTAL_UPDATE_THRESHOLD) {
        draft.sendFullUpdate = true;
      }
      break;
    case "label/SELECT_ALL_FIGURES":
      draft.selectedFigureId = -1;
      draft.multipleFigureSelectionList = draft.figures.map((figure) => figure.id);
      break;
    default:
      break;
  }
}, initLabelActivityState);
