import axios from "axios";

axios.defaults.withCredentials = true;

const submitClassification = submitData => {
    return axios.post('/api/classification/submit/', submitData);
};

const createBatchClass = (projectId, name, schema, category) => {
    return axios.post(`/api/class_batch_create/${projectId}/`, {name, schema, category})
};

const getClassData = projectID => {
    return axios.get(`/api/classes/${projectID}/`);
};

const addClass = projectID => {
    return axios.post(`/api/classes/${projectID}/`, {name: 'new class'});
}

const updateClassName = (classID, name) => {
    return axios.put(`/api/classes_edit/${classID}/`, {name})
}

const deleteClass = classId => {
    return axios.delete(`/api/classes_edit/${classId}/`);
}

const getSubclassData = classId => {
    return axios.get(`/api/class_categories/${classId}/`);
}

const addSubclass = (classId, name) => {
    return axios.post(`/api/class_categories/${classId}/`, {name});
}

const deleteSubclass = (subclassId) => {
    return axios.delete(`/api/class_categories_edit/${subclassId}/`);
}

const editSubclass = (subclassId, name) => {
    return axios.put(`/api/class_categories_edit/${subclassId}/`, {name});
}

const getClassAllData = projectID => {
    return axios.get(`/api/list_classes/${projectID}/`)
}

const classServices = {
    submitClassification,
    createBatchClass,
    getClassData,
    getClassAllData,
    deleteClass,
    getSubclassData,
    addSubclass,
    deleteSubclass,
    editSubclass,
    addClass,
    updateClassName,
};

export default classServices;
