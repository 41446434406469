import axios from "axios";

axios.defaults.withCredentials = true;

const getSchemaData = classId => {
    return axios.get(`/api/schema/${classId}/`);
};

const addSchema = (classId, field_name, property) => {
    return axios.post(`/api/schema/${classId}/`, {field_name, property});
}

const deleteSchema = (schemaId) => {
    return axios.delete(`/api/schema_edit/${schemaId}/`);
}

const editSchema = (schemaId, field_name, property, use_multiline) => {
    return axios.put(`/api/schema_edit/${schemaId}/`, {field_name, property,  use_multiline});
}

const sortSchema = (classId, order) => {
    return axios.put(`/api/change_schema_order/${classId}/`, {"order_list": order});
}

const setPostProcessingRule = (schemaId, postprocess_rule) => {
    return axios.put(`/api/schema_edit/${schemaId}/`, {postprocess_rule});
}

const getSchemaEvaluation = (regression_test_id, schemaId) => {
    return axios.get(`/api/analysis/regression_test_review_by_schema/${regression_test_id}/?schema_id=${schemaId}`);
}

const getGroupSchema = classId => {
    return axios.get(`/api/group_schema/${classId}/`)
}

const updateGroupSchema = (classId, group_schema) => {
    return axios.post(`/api/group_schema/${classId}/`, {group_schema})
}

const schemaServices = {getSchemaData, addSchema, deleteSchema, editSchema, sortSchema, setPostProcessingRule, getSchemaEvaluation, getGroupSchema, updateGroupSchema};
export default schemaServices;