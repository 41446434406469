import React from 'react';

const SSO_handler = React.lazy(() => import('../views/home/SSO_handler'))
const SignupPage = React.lazy(() => import('../views/home/SignupPage'));
const ResetPassword = React.lazy(() => import('../views/home/RestPassword'));

const publicRoutes = [
    {
        path: '/signup',
        component: SignupPage,
        exact: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: false,
    },
    {
        path: '/sso_callback',
        component: SSO_handler,
        exact: false
    }
];

export default publicRoutes;
