import {applyMiddleware, createStore, compose} from 'redux'
import {rootReducer} from './reducers/index'
import reduxPromise from "redux-promise-middleware"

const middleware = [reduxPromise]

let composedEnhancers;
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true, 
            traceLimit: 25
        }) || compose;
    composedEnhancers = composeEnhancers(applyMiddleware(...middleware));
} else {
    composedEnhancers = compose(applyMiddleware(...middleware));
}
// const composeEnhancers = (process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) : null) || compose;

const store = createStore(
    rootReducer,
    composedEnhancers
);

export default store