import axios from "axios";

axios.defaults.withCredentials = true;

const getLabeledImages = (projectID, taskID="") => {
    return axios.get(`/api/label/${projectID}/?task_id=${taskID}`);
};

const getClassifyImages = projectID => {
    return axios.get(`/api/classification_alternate/${projectID}/all/`);
};

const deleteImage = imageID => {
    return axios.delete(`/api/remove_image/${imageID}/`);
};

const getAllImages = projectID => {
    return axios.get(`/api/images/${projectID}/`)
}

const submitClassification = data => {
    return axios.post(`/api/classification/submit/`, data)
}


const getImages = (projectID, page, page_size, classID = '', subclassID = '', labelled = '', verified = '', classified = '', keyword = '', dataset='', assigned_classification='', assigned_labelling='', taskId='', source='') => {
    // let starting_entry = '', ending_entry = ''
    // if (page) {
    //     starting_entry = size * (page - 1)
    //     ending_entry = size * (page)
    // }
    let url = `/api/images/${projectID}/?`
    if (page && page > 0) {
        url = url.concat(`&page=${page}`)
    }
    if( page_size && page_size > 0) {
        url = url.concat(`&page_size=${page_size}`)
    }
    if (classID && classID.length > 0) {
        classID.map(id => {
            url = url.concat(`&class_id=${id}`)
            return true
        })
    }
    if (subclassID && subclassID.length > 0) {
        subclassID.map(id => {
            url = url.concat(`&class_category_id=${id}`)
            return true
        })
    }
    if (labelled && labelled && labelled.length > 0) {
        url = url.concat(`&labelled=${labelled}`)
    }
    if (verified && verified.length > 0) {
        url = url.concat(`&verified=${verified}`)
    }
    if (classified && classified.length > 0) {
        url = url.concat(`&classified=${classified}`)
    }
    if (keyword && keyword.length > 0) {
        url = url.concat(`&keyword=${keyword}`)
    }
    if (dataset && dataset !== '' ) {
        url = url.concat(`&dataset_id=${dataset}`)
    }
    if (assigned_classification && assigned_classification.length > 0) {
        url = url.concat(`&assigned_classification_task=${assigned_classification}`)
    }
    if (assigned_labelling && assigned_labelling.length > 0) {
        url = url.concat(`&assigned_labelling_task=${assigned_labelling}`)
    }
    if (taskId && taskId.length > 0) {
        url = url.concat(`&task_id=${taskId}`)
    }
    if (source && source.length > 0) {
        if (source === 'SinguIMG')
            source = ''
        url = url.concat(`&source=${source}`)
    }
    return axios.get(url)
}

const getCount = (projectID, classID = '', subclassID = '', labelled = '', verified = '', classified = '', keyword = '', datasetID='', assigned_classification='', assigned_labelling='', taskId='', source='') => {
    let url = `/api/images_count/${projectID}/?`
    if (classID && classID.length > 0) {
        classID.map(id => {
            url = url.concat(`&class_id=${id}`)
            return true
        })
    }
    if (subclassID && subclassID.length > 0) {
        subclassID.map(id => {
            url = url.concat(`&class_category_id=${id}`)
            return true 
        })
    }
    if (labelled && labelled && labelled.length > 0) {
        url = url.concat(`&labelled=${labelled}`)
    }
    if (verified && verified.length > 0) {
        url = url.concat(`&verified=${verified}`)
    }
    if (classified && classified.length > 0) {
        url = url.concat(`&classified=${classified}`)
    }
    if (keyword && keyword.length > 0) {
        url = url.concat(`&keyword=${keyword}`)
    }
    if (datasetID && datasetID !== '' ) {
        url = url.concat(`&dataset_id=${datasetID}`)
    }
    if (assigned_classification && assigned_classification.length > 0) {
        url = url.concat(`&assigned_classification_task=${assigned_classification}`)
    }
    if (assigned_labelling && assigned_labelling.length > 0) {
        url = url.concat(`&assigned_labelling_task=${assigned_labelling}`)
    }
    if (taskId && taskId.length > 0) {
        url = url.concat(`&task_id=${taskId}`)
    }
    if (source && source.length > 0) {
        url = url.concat(`&source=${source}`)
    }
    return axios.get(url)
}

const imageServices = {
    getLabeledImages,
    getAllImages,
    deleteImage,
    getClassifyImages,
    submitClassification,
    getImages,
    getCount
};

export default imageServices;
